import React from 'react';

import HeaderLanding from '../../partials/HeaderLanding';
import HeroLanding from '../../partials/HeroLanding';
import PageIllustration from '../../partials/PageIllustration';
import RoadMap from '../../partials/RoadMap';
import PricingLanding from '../../partials/PricingLanding';
import CaseStudies from '../../partials/CaseStudies';
import Faqs from '../../partials/Faqs';
import CtaLanding  from '../../partials/CtaLanding';

function Home() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <HeaderLanding/>

      {/*  Page content */}
      <main className="flex-grow">

        {/*  Page illustration */}
        <div className="relative max-w-6xl mx-auto h-0 pointer-events-none" aria-hidden="true">
          <PageIllustration />
        </div>

        {/*  Page sections */}
        <HeroLanding/>
        <RoadMap />
        <CaseStudies />
        <PricingLanding />
        <Faqs />
        <CtaLanding />
      </main>

    </div>
  );
}

export default Home;

