import React, {useState} from 'react';
import AppointmentApi from "../api/AppointmentApi";
import ApiCaller from "../lib/ApiCaller";
import {is} from "../shared/utils/validation";
import Modal from "../shared/utils/Modal";
import Thanks from "./Thanks";
import toast from "../shared/utils/toast";

const HeroBootcampForm = () => {

    const [name,setName] = useState('');
    const [phone,setPhone] = useState('');
    const [email,setEmail] = useState('');
    const [check,setCheck] = useState(false);
    const [notSended,setnotSended] = useState(true);
    const [videoModalOpen, setVideoModalOpen] = useState(false);

    const sendEmail = async (e:any) => {
        e.preventDefault();
        const api = new AppointmentApi(new ApiCaller());
        const userId =  'anonymous'
        is.email(email) && console.log(is.email(email) )

        if (email==='' ||  name==='' || phone==='') {
            toast.error('Por favor, introduce los campos que faltan.');
            return;
        }
        if (!check) {
            toast.error('Por favor, acepta las condiciones.') ;
            return;
        }

        await api.sendRequest({type:'bootcamp-request', name:name, request:phone, email:email },userId).then((resp:any) => {
            setnotSended(false);
            setVideoModalOpen(true);
            toast.success('Genial! Ya te hemos enviado un email.')
        }).catch(err => {
            toast.error('Ya hemos enviado un email a esta cuenta.')
        });
    }

  return ( <>
      { notSended && <div>
            <div className="max-w-3xl mx-auto text-left pb-2 md:pb-6">
                <p className="text-2xl text-green-100" data-aos="fade-up">¿Quieres saber más sobre nuestro Bootcamp?</p>
            </div>
            <form className="max-w-xl mx-auto">
                <div className="flex flex-wrap -mx-3 mb-4">
                   <div className="w-full md:w-1/2 text-left px-3 mb-4 md:mb-0">
                        <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="first-name">Nombre <span className="text-red-600">*</span></label>
                        <input id="first-name" type="text" className="form-input w-full text-gray-300  focus:border-red-500" placeholder="Nombre completo" required value={name} onChange={(e:any)=>{setName(e.target.value)}}/>
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                        <label className="block text-gray-300 text-left text-sm font-medium mb-1" htmlFor="last-name">Teléfono <span className="text-red-600">*</span></label>
                        <input id="last-name" type="text" className="form-input w-full text-gray-300  focus:border-red-500" placeholder="Te llamaremos" required value={phone} onChange={(e:any)=>{setPhone(e.target.value)}} />
                    </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                        <label className="block text-gray-300 text-left text-sm font-medium mb-1" htmlFor="email">Email <span className="text-red-600">*</span></label>
                        <input id="email" type="email" className="form-input w-full text-gray-300  focus:border-red-500" placeholder="Tu direccion email" required value={email} onChange={(e:any)=>{setEmail(e.target.value)}} />
                    </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                        <label className="flex items-center">
                         <input type="checkbox" className="form-checkbox border-red-500" onChange={(e:any)=>{setCheck(e.target.checked)}}/>
                            <span className="text-gray-500 ml-2 ">Acepto la regulación europea de protección de datos</span>
                        </label>
                    </div>
                </div>
                <div className="flex flex-wrap -mx-3 mt-6">
                    <div className="w-full px-3">
                        <button className="btn text-gray-900 bg-green-100 hover:bg-green-300 hover:text-gray-800 w-full" disabled={is.email(email)} onClick={sendEmail}>Solicitar información</button>
                    </div>
                </div>
            </form>
      </div> }

          <Modal id="modal" ariaLabel="modal-headline" show={videoModalOpen} handleClose={() => setVideoModalOpen(false)}>
              <div className="relative pb0">
                  <Thanks/>
              </div>
          </Modal>
      </>
  );
}

export default HeroBootcampForm;
