import React from 'react';

function Process() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-10 pb-12 md:pt-16 md:pb-10">

{/* Section header */}
  <div className="max-w-3xl mx-auto text-center pb-12 md:pb-10">
      <h2 className="h2 mb-4 title-apium" data-aos="fade-up"><span class="text-matching text-purple-600">Encontramos a profesionales</span> que verdaderamente quieran trabajar contigo</h2>
        <p className="text-xl text-gray-400 subtitle-apium" data-aos="fade-up" data-aos-delay="200">
              Los sistemas predictivos nos ayudan a entender cómo y por qué un profesional determinado va a conseguir
              encajar en tu organización, sea cual sea su tamaño.
            </p>
          </div>

          {/* Items */}
          <div className="max-w-sm mx-auto grid gap-8 md:grid-cols-3 lg:gap-16 items-start md:max-w-none">

{/* 1nd item */}
<div className="relative flex flex-col items-center" data-aos="fade-up" data-aos-delay="200">
<div aria-hidden="true" className="absolute h-1 border-t border-dashed border-gray-700 hidden md:block" style={{ width: 'calc(100% - 32px)', left: 'calc(50% + 48px)', top: '32px' }} data-aos="fade-in" data-aos-delay="400"></div>
<div class="iy">
<svg width="64" height="64" xmlns="http://www.w3.org/2000/svg">
<g fill="none"><path fill-opacity=".8" fill="#CCD4FF" d="M32 20h-2v11h-2v2h2v11h2v-4h2v-2h-2V26h2v-2h-2z"/>
<rect fill="#4A61DD" x="35" y="21" width="8" height="8" rx="1"/>
<rect fill="#4A61DD" x="35" y="35" width="8" height="8" rx="1"/>
<rect fill="#4A61DD" x="19" y="28" width="8" height="8" rx="1"/></g>
</svg>
</div>
<h6 className="h4 mb-2 text-purple-600 features-apium " >Apiumtalent AI</h6>
<p className="text-lg text-gray-400 text-center">Nuestra Inteligencia Artificial busca por ti y aceleramos tu proceso.</p>
</div>

{/* 2nd item */}
<div className="relative flex flex-col items-center" data-aos="fade-up" data-aos-delay="200">
<div aria-hidden="true" className="absolute h-1 border-t border-dashed border-gray-700 hidden md:block" style={{ width: 'calc(100% - 32px)', left: 'calc(50% + 48px)', top: '32px' }} data-aos="fade-in" data-aos-delay="400"></div>
<div class="iy">
<svg width="64" height="64" xmlns="http://www.w3.org/2000/svg"><g fill="none"><circle fill-opacity=".8" fill="#CCD4FF" cx="38.5" cy="25.5" r="4.5"/><circle fill="#4A61DD" cx="25.5" cy="25.5" r="4.5"/><circle fill="#4A61DD" cx="38.5" cy="38.5" r="4.5"/><circle fill-opacity=".8" fill="#CCD4FF" cx="25.5" cy="38.5" r="4.5"/></g></svg>
</div>
<h6 className="h4 mb-2 text-purple-600 features-apium">Apiumtalent 360</h6>
<p className="text-base  text-gray-400 text-center">Encontramos a tu equipo en entre 72 y 96 hs.</p>
</div>


{/* 3rd item */}
<div className="relative flex flex-col items-center" data-aos="fade-up" data-aos-delay="400">
<div class="iy">
<svg width="64" height="64" xmlns="http://www.w3.org/2000/svg"><g fill="none"><circle fill="#4A61DD" cx="32" cy="32" r="5"/><path d="M27.455 40.91a10 10 0 010-17.82 1 1 0 10-.91-1.781 12 12 0 000 21.382 1 1 0 10.911-1.782l-.001.001zm10-19.601a1 1 0 10-.91 1.781 10 10 0 010 17.82 1 1 0 00.909 1.78 12 12 0 000-21.382l.001.001z" fill-opacity=".8" fill="#CCD4FF"/></g></svg>
</div>
<h6 className="h4 mb-2 text-purple-600 features-apium">Talent Ambassadors</h6>
<p className="text-base  text-gray-400 text-center">Somos una extensión de tu empresa. Servicio ideal para startups en crecimiento o empresas con proyectos específicos. 
</p>
</div>

{/* 4rd item */}
<div className="relative flex flex-col items-center" data-aos="fade-up">
<div aria-hidden="true" className="absolute h-1 border-t border-dashed border-gray-700 hidden md:block" style={{ width: 'calc(100% - 32px)', left: 'calc(50% + 48px)', top: '32px' }} data-aos="fade-in" data-aos-delay="200"></div>
<div class="iy">
<svg width="64" height="64" xmlns="http://www.w3.org/2000/svg"><g fill="none"><path d="M29 21h-7a1 1 0 00-1 1v7a1 1 0 001 1h7a1 1 0 001-1v-7a1 1 0 00-1-1zm13 13h-7a1 1 0 00-1 1v7a1 1 0 001 1h7a1 1 0 001-1v-7a1 1 0 00-1-1zm-13 0h-7a1 1 0 00-1 1v7a1 1 0 001 1h7a1 1 0 001-1v-7a1 1 0 00-1-1z" fill="#4A61DD"/><path d="M37 26.586l-2-2L33.586 26l2.707 2.707a1 1 0 001.414 0L43.414 23 42 21.586l-5 5z" fill-opacity=".8" fill="#CCD4FF"/></g></svg>
</div>
<h6 className="h4 mb-2 text-purple-600 features-apium">Apiumtalent - Womentech</h6>
<p className="text-base  text-gray-400 text-center">Identificamos al mejor talento femenino en tecnología.
</p>
</div>

{/* 5rd item */}
<div className="relative flex flex-col items-center" data-aos="fade-up">
<div aria-hidden="true" className="absolute h-1 border-t border-dashed border-gray-700 hidden md:block" style={{ width: 'calc(100% - 32px)', left: 'calc(50% + 48px)', top: '32px' }} data-aos="fade-in" data-aos-delay="200"></div>
<div class="iy">
<svg width="64" height="64" xmlns="http://www.w3.org/2000/svg"><g fill="none"><rect fill="#4A61DD" x="21" y="21" width="14" height="17" rx="1"/><path d="M43.859 28.168L37.3 26.393l-.522 1.931 5.6 1.514-3.399 12.548-11.584-3.131-.521 1.931 12.55 3.393a1 1 0 001.226-.705l3.913-14.48a1 1 0 00-.704-1.226z" fill-opacity=".8" fill="#CCD4FF"/></g></svg>
</div>
<h6 className="h4 mb-2 text-purple-600 features-apium"> Apiumtalent & Apiumtech</h6>
<p className="text-base  text-gray-400 text-center">Cuando Apiumtech realiza tu proyecto, su equipo luego podrá ser el que continúe trabajando en tu compañía cuando lo necesites in-house.  </p>
</div>

{/* 6rd item */}
<div className="relative flex flex-col items-center" data-aos-delay="400">
<div class="iycamp">
<svg width="64" height="64" xmlns="http://www.w3.org/2000/svg"><path d="M43.58 20.424a1 1 0 00-.761-.294c-14.028.732-19.21 13.228-19.26 13.354a1 1 0 00.22 1.08l5.657 5.657a1 1 0 001.085.218c.125-.051 12.554-5.291 13.348-19.253a1 1 0 00-.289-.762zm-8.166 10.99a2 2 0 11-2.829-2.828 2 2 0 012.829 2.828z" fill="#03ef62"/><path d="M21.113 38.844a2.844 2.844 0 114.022 4.022C24.024 43.977 20 44 20 44s0-4.046 1.113-5.156zm9.244-16.503a8.911 8.911 0 00-7.835 2.484 9.06 9.06 0 00-1.384 1.8 1 1 0 00.155 1.215l1.989 1.99a26.623 26.623 0 017.075-7.489zm11.302 11.302a8.911 8.911 0 01-2.484 7.835 9.06 9.06 0 01-1.8 1.384 1 1 0 01-1.215-.155l-1.99-1.989a26.623 26.623 0 007.489-7.075z" fill-opacity=".8" fill="#03ef62"/></svg>
</div>
<h6 className="h4 mb-2 text-green-100 features-apium">Bootcamp</h6>
<p className="text-base text-gray-400 text-center"> Conviértete en un desarrollador de software profesional.
<span className="block"><strong className="text-green-100">En 10 semanas</strong></span></p>
</div>

</div>

 {/* Features button */}
<div className="m-20 relative flex flex-col items-center" data-aos="fade-up">
<a className="btn text-white bg-purple-600 hover:bg-purple-700" href="/features">
<span className="text-xl">Más información Apiumtalent</span>
<svg className="w-3 h-3 fill-current text-purple-400 flex-shrink-0 ml-2" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
<path d="M6 5H0v2h6v4l6-5-6-5z" />
</svg>
</a>
</div>

  </div>
    </div>
    
    </section>
  );
}

export default Process;
