import LendsCollection, { LendsGroup } from '../lib/common/models/lendsCollection';

//------------  state definition
export interface LendsState {
    lends: LendsGroup
    ready: boolean
}

//------------ actions
export const ADD_LENDS = 'ADD_LENDS'

interface AddLendsAction {
  type: typeof ADD_LENDS
  payload: LendsGroup
}

export function addLends(lends: LendsGroup): LendsActionTypes {
    return {
      type: ADD_LENDS,
      payload: lends
    }
}

export type LendsActionTypes = AddLendsAction;

//------------ reducer
const initialState: LendsState = {
    lends: {
      finished: new LendsCollection([]),
      unfinished: new LendsCollection([])
    },
    ready: false
}

export function lendsReducer( state = initialState, action: LendsActionTypes): LendsState {
  switch (action.type) {
    case ADD_LENDS: {
      return {
        ...state,
        lends: action.payload,
        ready: true
      }
    }
    default:
      return state
  }
}