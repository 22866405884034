import React from 'react';

import PageIllustration from '../partials/PageIllustration';
import { Link } from 'react-router-dom';
import BootcampProcess from "../partials/BootcampProcess";
import Header from "../partials/Header";
import Footer from "../partials/Footer";

const BootcampSteps = () => {

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

        <Header />

      <main className="flex-grow">

        <div className="relative max-w-6xl mx-auto h-0 pointer-events-none" aria-hidden="true">
          <PageIllustration />
        </div>

        <BootcampProcess/>

      </main>

        <Footer />
    </div>
  );
}

export default BootcampSteps;
