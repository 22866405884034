import React from 'react';

const TimelineBootcampOnline = () => {

  const items = [
      {block:'Bloque 1', blockTitle:'Conocimientos básicos', title: 'Fundamentos de programación',
        description: 'Aprenderas las bases y los fundamentos de la lógica de programación así como el funcionamiento de las herramientas de desarrollo. Cómo funcionan los algoritmos y cuales son las estructuras de software que vas a utilizar.'},
      {block:'Bloque 2', blockTitle:'Front-End', title: 'Programación front-end',
        description: 'Nos metemos de lleno en la programación de la parte web de un producto digital. Trabajaremos con ReactJS y typescript, adaptando templates existentes, conectando con servicios externos...'},
      {block:'Bloque 3', blockTitle:'Back-End', title: 'Programación back-end',
        description: 'Construir un API en NodeJS para que pueda ser usada desde la aplicación front-end. Trabajo con librerías existentes. Este api seguirá los estándares en materia de seguridad, escalabilidad y adaptabilidad.'},
      {block:'Bloque 4', blockTitle:'Monorepo', title: 'Desarrollo de tu primera aplicación',
        description: 'Desarrollo completo de una aplicación online'},
      {block:'Bloque 5', blockTitle:'Trabajo en equipo', title: 'Desarrollo ágil en equipos distribuidos',
        description: 'No solo codificar va a ser necesario para que te puedas integrar en un equipo. Es necesario conocer la metodología Agile y también las herramientas más comunes.'},
      {block:'Bloque 6', blockTitle:'Plan de carrera', title: 'Preparación del perfil profesional',
        description: 'Vamos a explicarte qué se espera de tí, y como puedes adaptarte a cada necesidad concreta que te plantee tu contratador. Cómo orientar tu carrera para conseguir un aprendizaje continuo.'},
      ];

  return (

  <section>
    <div className="max-w-6xl mx-auto px-4 sm:px-6">
      <div className="py-12 md:py-20 border-t border-gray-800">
        <div className="py-12 md:py-20">

    {/* Section header */}
      <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
        <h2 className="h2 title-apium text-green-100 mb-4">Programa</h2>
        <p className="text-xl text-gray-400">6 Pasos en <span className="text-xl text-green-100">10 semanas</span> para acceder en un mercado laboral en constante crecimiento.</p>
      </div>

    {/* Items */}
      <div className="max-w-3xl mx-auto -my-4 md:-my-6" data-aos-id-timeline>

    {/* 1st item */}
      { items.map((i, index)=><div className="relative py-4 md:py-6 pl-24" data-aos="fade-up" data-aos-delay={index*200} data-aos-anchor="[data-aos-id-timeline]">
        <div className="pl-2">
          <div className="font-architects-daughter text-xl text-green-100 mb-2">{i.blockTitle}</div>
            <div className="flex items-center mb-3">
              <div className="absolute left-0 inline-flex text-sm font-semibold py-1 px-3 text-gray-900 bg-green-200 rounded-full">{i.block}</div>
              <div className="absolute left-0 h-full px-px bg-green-100 ml-20 self-start transform -translate-x-1/2 translate-y-3" aria-hidden="true"></div>
              <div className="absolute left-0 w-2 h-2 bg-green-100 border-4 box-content border-gray-900 rounded-full ml-20 transform -translate-x-1/2" aria-hidden="true"></div>
              <h4 className="h4 title-apium text-green-100">{i.title}</h4>
            </div>
            <p className="text-lg text-gray-200">{i.description}</p>
          </div>
        </div>)}

          </div>

        </div>
      </div>
      </div>
    </section>
  );
}

export default TimelineBootcampOnline;
