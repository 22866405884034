import { combineReducers, createStore } from 'redux'
import { sessionReducer } from './sessionStore'
import { lendsReducer } from './lendsStore';
import { docsReducer } from './docsStore';
import { dossierReducer } from './dossierStore';
import {templatesReducer} from "./templateStore";

const appReducer = combineReducers({
    session: sessionReducer,
    lends: lendsReducer,
    current: docsReducer,
    templates: templatesReducer,
    dossier: dossierReducer
});

export type AppState = ReturnType<typeof appReducer>

export default function configureStore() {
    const store = createStore(appReducer);
    return store;
}
