import React, {useState} from 'react';

import PageIllustration from '../partials/PageIllustration';
import Header from "../partials/Header";
import Footer from "../partials/Footer";

const BootcampProgram = () => {

    const [page, setPage] = useState(1);

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

        <Header />

      <main className="flex-grow">

        <div className="relative max-w-6xl mx-auto h-0 pointer-events-none" aria-hidden="true">
          <PageIllustration />
        </div>

{/*  Page sections */}
    <div className="relative max-w-6xl mx-auto px-4 sm:px-6  pt-12 ">
    
    <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          
           <div className="flex flex-col md:flex-row">

{/* Main content */}
     <main className="md:flex-auto md:pl-10 order-1" data-aos="fade-up">
            {[1,2,3,4,5].map((i)=> <div className={page !== i ? 'hidden' : undefined}>
                <div className="mb-8">
                    <h2 className="h2 title-bootcamp text-green-600 mb-4">Preparación {i}</h2>
                    <p className="text-gray-400">Duración - <span className="text-green-600">80 horas</span></p>
                 </div>
                 <ul className="-my-4">
                                  <li className="py-4">
                                      <h4 className="text-xl text-gray-100 font-medium mb-2">Objetivos</h4>
                                      <p className="text-lg text-gray-400">Nuestros servicios son ideales para contratación de los mejores PERFILES TECNOLÓGICOS
                                      </p>
                                      <li className="flex items-center mb-2">
                                          <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                          </svg>
                                          <span>Si necesitas contratar a los mejores cuanto antes.</span>
                                      </li>
                                  </li>

                                  <li className="py-4">
                                      <h4 className="text-xl font-medium mb-2">Contenidos</h4>
                                      <p className="text-lg text-gray-400">Puedes utilizar nuestro servicio para cubrir entre 1-100 perfiles en simultáneo; o inclusive más.</p>
                                  </li>

                                  <li className="py-4">
                                      <h4 className="text-xl font-medium mb-2">Tecnologias</h4>
                                      <li className="flex items-center mb-2">
                                          <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                          </svg>
                                          <span>Rapidez: Puedes reclutar a tu equipo completo en 72 Hs.</span>
                                      </li>
                                  </li>

                                  <li className="py-4">
                                      <h4 className="text-xl font-medium mb-2">Proyecto</h4>
                                      <p className="text-lg text-gray-400">Si Debes armar un proyecto desde cero y luego necesitas contar con el mejor personal tecnológico dentro de tu empresa. Este es tu servicio ideal; más económico, más práctico y más eficaz.</p>
                                  </li>
                                  <li className="py-4">
                                      <h4 className="text-xl font-medium mb-2">Capacitación</h4>
                                      <p className="text-lg text-gray-400">Si,nuestros servicios funcionan por igual en todo el mundo. </p>
                                  </li>
                              </ul>
                          </div>)}


                      </main>

                      {/* Nav sidebar */}
                      <aside className="md:w-64 mb-16 md:mb-0 md:mr-10 md:flex-shrink-0" data-aos="fade-up" data-aos-delay="200">
                          <h4 className="text-lg font-medium px-3 pb-3 border-b border-gray-800">Programa del curso</h4>
                          <nav>
                              <ul>
                                  {[1,2,3,4,5].map((i)=> <li className="py-2 border-b border-gray-800">
                                      <a
                                          className={`flex items-center px-3 group text-gray-400 hover:text-green-600 transition duration-150 ease-in-out ${page === i && 'text-green-600'}`}
                                          href="#0"
                                          onClick={(e) => { e.preventDefault(); setPage(i); }}
                                      >
                                          <span>Preparación {i}</span>
                                          <svg className="w-3 h-3 fill-current flex-shrink-0 ml-2 opacity-0 group-hover:opacity-100 group-hover:text-green-600 group-hover:translate-x-1 transition duration-150 ease-in-out transform" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" />
                                          </svg>
                                      </a>
                                  </li>)}
                              </ul>
                          </nav>
                      </aside>

                  </div>
              </div>
          </div>

      </main>

        <Footer />
    </div>
  );
}

export default BootcampProgram;
