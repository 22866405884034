import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Dropdown from "../shared/utils/Dropdown";

function Header() {

  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const mobileNav = useRef(null);

  // close the mobile menu on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!mobileNavOpen || mobileNav.current.contains(target)) return;
      setMobileNavOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close the mobile menu if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!mobileNavOpen || keyCode !== 27) return;
      setMobileNavOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  return (
    <header className="absolute w-full z-30">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 header-apium">
        <div className="flex items-center justify-between h-20">

          {/* Site branding */}
          <div className="flex-shrink-0 mr-4">
            {/* Logo */}
            <Link to="/" className="block" aria-label="Apiumtalent">
            <img className="object-cover" src={require('../images/apiumtalent_logo.svg')} width="190" height="170" alt="logo" />
            </Link>
          </div>

{/* Desktop navigation */}
    <nav className="hidden md:flex md:flex-grow">

{/* Desktop menu links */}
  <ul className="flex flex-grow justify-end flex-wrap items-center menu-apium">

   <li>
      <Link to="/apiumtalent" className="text-gray-300 hover:text-purple-600 px-4 py-2 flex items-center transition duration-150 ease-in-out">
      Apiumtalent></Link>
      </li>

    <li>
      <Link to="/contact" className="text-gray-300 hover:text-purple-600 px-4 py-2 flex items-center transition duration-150 ease-in-out">
      ¿Hablamos?</Link>
    </li>

   <li>
    <Link to="/help" className="text-gray-300 hover:text-purple-600 px-4 py-2 flex items-center transition duration-150 ease-in-out">
      FAQ</Link>
    </li>

    {/*<li>*/}
    {/*  <Link to="/bootcamp" className="btn-sm text-gray-900 bg-green-100 hover:bg-green-200 hover:text-green-100 ml-3"> </Link>*/}
    {/*  </li>*/}

    <Dropdown title="Bootcamp Mayo/Septiembre">
      <li><Link to="/bootcamp"          className="font-medium text-sm text-gray-400 hover:text-green-100  flex py-2 px-4 leading-tight">Andorra-Septiembre</Link></li>
      <li><Link to="/bootcamp/mecanica" className="font-medium text-sm text-gray-400 hover:text-green-100  flex py-2 px-4 leading-tight">Mecánica</Link></li>
      <li><Link to="/bootcamp/programa" className="font-medium text-sm text-gray-400 hover:text-green-100  flex py-2 px-4 leading-tight">Programa</Link></li>
      <li><Link to="/bootcamp/sponsors" className="font-medium text-sm text-gray-400 hover:text-green-100  flex py-2 px-4 leading-tight">Sponsors</Link></li>
    </Dropdown>

            </ul>

            {/* Desktop sign in links */}
            <ul className="flex flex-grow justify-end flex-wrap items-center">
              <li>
                <Link to="/candidatos" className="font-medium text-purple-600 hover:text-gray-200 px-4 py-3 flex items-center transition duration-150 ease-in-out">Candidatos</Link>
              </li>
              <li>
                <Link to="/empresas" className="btn-sm text-white bg-purple-600 hover:bg-purple-700 ml-3">Empresas</Link>
              </li>
            </ul>

          </nav>

{/* Mobile menu */}
          <div className="md:hidden">

{/* Hamburger button */}
            <button className={`hamburger ${mobileNavOpen && 'active'}`} aria-controls="mobile-nav" aria-expanded={mobileNavOpen} onClick={() => setMobileNavOpen(!mobileNavOpen)}>
              <span className="sr-only">Menu</span>
              <svg className="w-6 h-6 fill-current text-gray-300 hover:text-gray-200 transition duration-150 ease-in-out" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <rect y="4" width="24" height="2" rx="1" />
                <rect y="11" width="24" height="2" rx="1" />
                <rect y="18" width="24" height="2" rx="1" />
              </svg>
            </button>

            {/*Mobile navigation */}
            <nav id="mobile-nav" ref={mobileNav} className="absolute top-full z-20 left-0 w-full px-4 sm:px-6 overflow-hidden transition-all duration-300 ease-in-out"
                 style={mobileNavOpen ? { maxHeight: mobileNav.current.scrollHeight, opacity: 1 } : { maxHeight: 0, opacity: .8 } }>
              <ul className="bg-gray-800 px-4 py-2">
                <li>
                  <Link to="/features" className="flex text-gray-300 hover:text-gray-200 py-2" onClick={() => setMobileNavOpen(false)}>Apiumtalent</Link>
                </li>
                <li>
                  <Link to="/bootcamp" className="flex text-gray-300 hover:text-gray-200 py-2">Bootcamp</Link>
                </li>

                <li className="py-2 my-2 border-t border-b border-gray-700">
                  <span className="flex text-gray-300 py-2">Soporte</span>
                  <ul className="pl-4">
                    <li>
                      <Link to="/faq" className="text-sm flex font-medium text-gray-400 hover:text-gray-200 py-2">faq</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/signin" className="flex font-medium w-full text-purple-600 hover:text-gray-200 py-2 justify-center">Candidatos</Link>
                </li>
                <li>
                  <Link to="/signup" className="font-medium w-full inline-flex items-center justify-center border border-transparent px-4 py-2 my-2 rounded-sm text-white bg-purple-600 hover:bg-purple-700 transition duration-150 ease-in-out">Empresas</Link>
                </li>
              </ul>
            </nav>

          </div>

        </div>
      </div>
    </header>
  );
}

export default Header;
