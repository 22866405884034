import React from 'react';
import AppointmentApi from "../api/AppointmentApi";
import ApiCaller from "../lib/ApiCaller";
import HeroBootcampForm from "./HeroBootcampForm";

const HeroBootcamp = () => {

  return (
    <section className="relative md:pt-20 md:pb-20">

      <div className="absolute inset-0">
        <img className="w-full h-full object-cover" src={require('../images/thisisengineering.jpg')} width="1440" height="394" alt="About" />
        <div className="absolute inset-0 bg-gray-900 opacity-75" aria-hidden="true"></div>
      </div>

        <div className="md:grid md:grid-cols-12 md:gap-1 items-center">
            <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-4 lg:col-span-4 md:mb-0 md:order-1">
                <div className="max-w-4xl mx-auto px-4 sm:px-6 relative ">
                    <div className="pt-32 pb-12 md:pt-40 md:pb-0">
                        <div className="max-w-3xl mx-auto text-center">
                            <HeroBootcampForm/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-7 ml-8">
                <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
                    <div className="pt-32 pb-12 md:pt-40 md:pb-20">
                        <div className="max-w-3xl mx-auto text-center">
                            <h1 className="h1 title-apium text-green-100 text-left  mb-4" data-aos="fade-up">Conviértete en un desarrollador de software profesional</h1>
                            <h3 className="h3 title-apium text-left  mb-4" data-aos="fade-up">ONLINE / PRESENCIAL</h3>
                            <h3 className="h3 title-apium text-left  mb-4" data-aos="fade-up">Desarrollo WEB FULL STACK</h3>
                            <p className="text-xxl text-left text-green-100 mb-8" data-aos="fade-up" data-aos-delay="200">PART TIME / FULL TIME</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </section>
  );
}

export default HeroBootcamp;
