import MYDocument from '../lib/common/models/myDocument';
import NZDocument, {NZDocumentType} from '../lib/common/models/nzDocument';

//------------  state definition
export interface DocsState {
    doc: MYDocument
    drafts: MYDocument[]
    signed: NZDocument[]
    ready: boolean
}

//------------ actions
export const SET_CURRENT_DOC = 'SET_CURRENT_DOC'
export const GET_DRAFTS_LIST = 'GET_DRAFTS_LIST'
export const GET_SIGNED_LIST = 'GET_SIGNED_LIST'

interface SetCurrentDocAction {
  type: typeof SET_CURRENT_DOC
  payload: MYDocument
}

interface GetDraftsAction {
  type: typeof GET_DRAFTS_LIST
  payload: MYDocument[]
}

interface GetSignedAction {
  type: typeof GET_SIGNED_LIST
  payload: NZDocument[]
}

export function setCurrentDoc(doc: MYDocument): DocsActionTypes {
    return {
      type: SET_CURRENT_DOC,
      payload: doc
    }
}
export function setCurrentDrafts(drafts: MYDocument[]) : DocsActionTypes  {
    return {
      type: GET_DRAFTS_LIST,
      payload: drafts
    }
}

export function setCurrentSignedDocuments(drafts: NZDocument[]) : DocsActionTypes  {
    return {
      type: GET_SIGNED_LIST,
      payload: drafts
    }
}

export type DocsActionTypes = SetCurrentDocAction | GetDraftsAction | GetSignedAction;

//------------ reducer
const initialState: DocsState = {
    doc: new MYDocument({type:NZDocumentType.GENERAL_PURPOSE,customName:'',customDescription:'', content:''}),
    ready: false,
    drafts: [],
    signed: [],

}

export function docsReducer( state = initialState, action: DocsActionTypes): DocsState {
  switch (action.type) {
    case SET_CURRENT_DOC: {
      return {
        ...state,
          doc: action.payload,
          ready: true
      }
    }
    case GET_DRAFTS_LIST: {
      return {
        ...state,
          drafts: action.payload,
          ready: true
      }
    }
    case GET_SIGNED_LIST: {
      return {
        ...state,
          signed: action.payload,
          ready: true
      }
    }
    default:
      return state
  }
}
