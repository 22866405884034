import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer>

<div className="py-12 md:py-16">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 text-center">

          {/* Top area: Blocks */}
          <div className="grid md:grid-cols-12 gap-8 lg:gap-20 mb-8 md:mb-12">

            {/* 1st block */}
            <div className="md:col-span-12 lg:col-span-12">
              <div className="mb-2">
                {/* Logo */}
                <Link to="/" className="inline-block" aria-label="Cruip">
  <img className="object-cover" src={require('../images/logo-apiumtalent.png')} width="120" height="100" alt="logo" />
                </Link>
              </div>
<div className="text-lg text-gray-400 max-w-3xl mx-auto text-center pb-12 md:pb-16">
“El éxito de una compañía radica en las personas, siempre en las personas...cualquiera sea su industria, las personas serán siempre su mayor ventaja competitiva” 
<div className="block text-purple-600">Richard Brandson</div>
</div>

</div>


</div>

{/* Bottom area */}
<div className="md:flex md:items-center md:justify-between">
  
  {/* Content links */}
   <ul className="flex mb-4 md:order-1 md:ml-4 md:mb-0 text-right">
       <li>
      <Link to="/features" className="text-gray-300 hover:text-purple-600 px-4 py-2 flex items-center transition duration-150 ease-in-out">
       Apiumtalent</Link>
     </li>

      <li>
      <Link to="/bootcamp" className="text-green-100 hover:text-green-100 px-4 py-2 flex items-center transition duration-150 ease-in-out">
       Bootcamp ∫ Mayo 2023 </Link>
     </li>

      <li>
      <Link to="/help" className="text-gray-300 hover:text-purple-600 px-4 py-2 flex items-center transition duration-150 ease-in-out">
       FAQ</Link>
     </li>

   </ul>

  {/* Social links */}
    <ul className="flex mb-4 md:order-1 md:ml-4 md:mb-0">
        <li>
        <Link to="#" className="flex justify-center items-center text-purple-600 bg-gray-800 hover:text-gray-100 hover:bg-purple-600 rounded-full transition duration-150 ease-in-out" aria-label="Twitter">
            <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <path d="M24 11.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H8c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z" />
            </svg>
        </Link>
        </li>

<li className="ml-4">
  <Link to="#" target="_blank" className="flex justify-center items-center text-purple-600 bg-gray-800 hover:text-gray-100 hover:bg-purple-600 rounded-full transition duration-150 ease-in-out" aria-label="Instagram">
  <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
  <circle cx="20.145" cy="11.892" r="1" />
  <path d="M16 20c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-6c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z" />
  <path d="M20 24h-8c-2.056 0-4-1.944-4-4v-8c0-2.056 1.944-4 4-4h8c2.056 0 4 1.944 4 4v8c0 2.056-1.944 4-4 4zm-8-14c-.935 0-2 1.065-2 2v8c0 .953 1.047 2 2 2h8c.935 0 2-1.065 2-2v-8c0-.935-1.065-2-2-2h-8z" />
  </svg>
  </Link>
</li>
<li className="ml-4">
  <Link to="http://www.linkedin.com" target="_blank" className="flex justify-center items-center text-purple-600 bg-gray-800 hover:text-gray-100 hover:bg-purple-600 rounded-full transition duration-150 ease-in-out" aria-label="Linkedin">
  <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
  <path d="M23.3 8H8.7c-.4 0-.7.3-.7.7v14.7c0 .3.3.6.7.6h14.7c.4 0 .7-.3.7-.7V8.7c-.1-.4-.4-.7-.8-.7zM12.7 21.6h-2.3V14h2.4v7.6h-.1zM11.6 13c-.8 0-1.4-.7-1.4-1.4 0-.8.6-1.4 1.4-1.4.8 0 1.4.6 1.4 1.4-.1.7-.7 1.4-1.4 1.4zm10 8.6h-2.4v-3.7c0-.9 0-2-1.2-2s-1.4 1-1.4 2v3.8h-2.4V14h2.3v1c.3-.6 1.1-1.2 2.2-1.2 2.4 0 2.8 1.6 2.8 3.6v4.2h.1z" />
  </svg>
  </Link>
</li>
</ul>

{/* Copyrights note */}
  <div className="text-gray-400 text-sm mr-4">&copy; 2023 Apiumtech. All rights reserved.</div>
  </div>
  </div>
  </div>
  </footer>
  );
}
export default Footer;
