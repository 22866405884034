interface LegalModelInfo {
  id?: string;
  title: string;
  langCode: string;
  source?: string;
  slug?: string;
  legalContent: any;
  metadata: any;
  parent?: string;
  category?: string;
}

class LegalModel {
  id?: string;
  title: string;
  langCode: string;
  source?: string;
  slug?: string;
  legalContent?: any;
  metadata?: any;
  parent?: string;
  category?: string;

  constructor(legalModelInfo: LegalModelInfo) {
    this.id = legalModelInfo.id;
    this.langCode = legalModelInfo.langCode;
    this.title = legalModelInfo.title;
    this.source = legalModelInfo.source;
    this.slug = legalModelInfo.slug;
    this.legalContent = legalModelInfo.legalContent;
    this.metadata = legalModelInfo.metadata;
    this.parent = legalModelInfo.parent;
    this.category = legalModelInfo.category;
  }

  load(data: LegalModelInfo): LegalModel {
    return new LegalModel(data);
  }

  toObject(): LegalModelInfo {
    return {
      id: this.id,
      title: this.title,
      langCode: this.langCode,
      slug: this.slug,
      legalContent: this.legalContent,
      metadata: this.metadata,
      parent: this.parent,
      category: this.category,
    };
  }
}
export class LegalModelLite {
  id?: string;
  title: string;
  langCode: string;
  slug?: string;
  description?: any;
  parent?: string;
  category?: string;

  constructor(legalModelInfo: LegalModelInfo) {
    this.id = legalModelInfo.id;
    this.langCode = legalModelInfo.langCode;
    this.title = legalModelInfo.title;
    this.description = legalModelInfo.metadata.description;
    this.slug = legalModelInfo.slug;
    this.parent = legalModelInfo.parent;
    this.category = legalModelInfo.category;
  }

  load(data: LegalModelInfo): LegalModelLite {
    return new LegalModelLite(data);
  }

  toObject(): any {
    return {
      id: this.id,
      title: this.title,
      langCode: this.langCode,
      slug: this.slug,
      description: this.description,
      parent: this.parent,
      category: this.category,
    };
  }
}

export default LegalModel;
