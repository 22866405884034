import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

function HeaderLanding() {

  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const mobileNav = useRef(null);

  // close the mobile menu on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!mobileNavOpen || mobileNav.current.contains(target)) return;
      setMobileNavOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close the mobile menu if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!mobileNavOpen || keyCode !== 27) return;
      setMobileNavOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  return (
    <header className="absolute w-full z-30">
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 header-apium ">
        <div className="flex items-center justify-between h-20">

          {/* Site branding */}
          <div className="flex-shrink-0 mr-4">
            {/* Logo */}
            <Link to="#" className="block" aria-label="Apiumtalent">
            <img className="object-cover" src={require('../images/bootcamp-logo.svg')} width="270" height="250" alt="logo bootcamp" />
            </Link>
          </div>

{/* Desktop navigation */}
    <nav className="hidden md:flex md:flex-grow pt-10">

{/* Desktop menu links */}
  <ul className="flex flex-grow justify-end flex-wrap items-center menu-apium">

      <li>
      <a href="#proceso" className="text-gray-300 hover:text-green-100 px-4 py-2 flex items-center transition duration-200 " >Proceso de solicitud </a>
      </li>

      <li>
      <a href="#programa" className="text-gray-300 hover:text-green-100 px-4 py-2 flex items-center transition duration-200 " >Programa</a>
      </li>

       <li>
      <a href="#comienzatuviaje" className="text-gray-300 hover:text-green-100 px-4 py-2 flex items-center transition duration-200 " >COMIENZA TU VIAJE</a>
      </li>

      <li>
      <a href="#dudas" className="text-gray-300 hover:text-green-100 px-4 py-2 flex items-center transition duration-200 smooth-scroll">¿Tienes alguna duda?</a>
      </li>

  

            </ul>

            {/* Desktop sign in links 
            <ul className="flex flex-grow justify-end flex-wrap items-center">
              <li>
                <Link to="/candidatos" className="font-medium text-purple-600 hover:text-gray-200 px-4 py-3 flex items-center transition duration-150 ease-in-out">Candidatos</Link>
              </li>
              <li>
                <Link to="/empresas" className="btn-sm text-white bg-purple-600 hover:bg-purple-700 ml-3">Empresas</Link>
              </li>
            </ul>*/}

          </nav>

{/* Mobile menu */}
          <div className="md:hidden">

{/* Hamburger button */}
            <button className={`hamburger ${mobileNavOpen && 'active'}`} aria-controls="mobile-nav" aria-expanded={mobileNavOpen} onClick={() => setMobileNavOpen(!mobileNavOpen)}>
              <span className="sr-only">Menu</span>
              <svg className="w-6 h-6 fill-current text-gray-300 hover:text-gray-200 transition duration-150 ease-in-out" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <rect y="4" width="24" height="2" rx="1" />
                <rect y="11" width="24" height="2" rx="1" />
                <rect y="18" width="24" height="2" rx="1" />
              </svg>
            </button>

            {/*Mobile navigation */}
            <nav id="mobile-nav" ref={mobileNav} className="absolute top-full z-20 left-0 w-full px-4 sm:px-6 overflow-hidden transition-all duration-300 ease-in-out"
                 style={mobileNavOpen ? { maxHeight: mobileNav.current.scrollHeight, opacity: 1 } : { maxHeight: 0, opacity: .8 } }>
              <ul className="bg-gray-800 px-4 py-2">
                <li>
                  <Link to="/features" className="flex text-gray-300 hover:text-gray-200 py-2" onClick={() => setMobileNavOpen(false)}>Apiumtalent</Link>
                </li>
                <li>
                  <Link to="/bootcamp" className="flex text-gray-300 hover:text-gray-200 py-2">Bootcamp</Link>
                </li>

                <li className="py-2 my-2 border-t border-b border-gray-700">
                  <span className="flex text-gray-300 py-2">Soporte</span>
                  <ul className="pl-4">
                    <li>
                      <Link to="/faq" className="text-sm flex font-medium text-gray-400 hover:text-gray-200 py-2">faq</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/signin" className="flex font-medium w-full text-purple-600 hover:text-gray-200 py-2 justify-center">Candidatos</Link>
                </li>
                <li>
                  <Link to="/signup" className="font-medium w-full inline-flex items-center justify-center border border-transparent px-4 py-2 my-2 rounded-sm text-white bg-purple-600 hover:bg-purple-700 transition duration-150 ease-in-out">Empresas</Link>
                </li>
              </ul>
            </nav>

          </div>

        </div>
      </div>
    </header>
  );
}

export default HeaderLanding;
