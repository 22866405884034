import React from 'react';

function Cta() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
        <div className="pt-12 md:pt-20 border-t border-b border-gray-800">
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16" data-aos-id-cta>
{/* Image */}
              <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:rtl" data-aos="fade-right" data-aos-delay="200" data-aos-anchor="[data-aos-id-target]">
                <img className="mx-auto md:max-w-none" src={require('../images/Saly-13.png')} width="340" height="320" alt="Features 02" />
              </div>

            {/* Section header */}
            <h1 className="h2 mb-4 text-green-100 title-apium" data-aos="fade-up" data-aos-anchor="[data-aos-id-cta]">
            🏔 ANDORRA BOOTCAMP <span className="block">Desarrollo WEB FULL STACK </span> </h1>
            <h3 className="h3 mb-4 text-red-100 title-apium" data-aos="fade-up" data-aos-anchor="[data-aos-id-cta]">Próxima convocatoria: Mayo 2023</h3>
            <p className="text-xl text-gray-400 mb-8" data-aos="fade-up" data-aos-delay="200" data-aos-anchor="[data-aos-id-cta]"><strong>6 Pasos en 10 semanas</strong> para acceder en un mercado laboral en constante crecimiento.</p>

            {/* CTA button */}
            <div className="flex justify-center mb-8" data-aos="fade-up" data-aos-delay="400" data-aos-anchor="[data-aos-id-cta]">
              <a className="btn text-white bg-purple-600 hover:bg-purple-700" href="/bootcamp">Quiero saber más</a>
            </div>

            {/* Check list */}
            <ul className="flex flex-wrap justify-center text-lg text-gray-400 -mx-2 -my-1">
              <li className="flex items-center mx-3 my-1" data-aos="fade-up" data-aos-delay="600" data-aos-anchor="[data-aos-id-cta]">
                <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                </svg>
                <span>Metodología: Presencial</span>
              </li>
              <li className="flex items-center mx-3 my-1" data-aos="fade-up" data-aos-delay="800" data-aos-anchor="[data-aos-id-cta]">
                <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                </svg>
                <span>Duración: 10 Semanas</span>
              </li>
              <li className="flex items-center mx-3 my-1" data-aos="fade-up" data-aos-delay="1000" data-aos-anchor="[data-aos-id-cta]">
                <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                </svg>
                <span>Convocatoria: Mayo 2023</span>
              </li>
            </ul>

          </div>
        </div>
      </div>
    </section>
  );
}

export default Cta;
