import React, { useState, useRef, useEffect } from 'react';
import Transition from '../shared/utils/Transition.js';

function RoadMap() {

  const [tab, setTab] = useState(1);

  const tabs = useRef(null);

  const heightFix = () => {
    if (tabs.current.children[tab]) {
      tabs.current.style.height = tabs.current.children[tab - 1].offsetHeight + 'px'
    }
  }
  
  useEffect(() => {
    return () => {
      heightFix()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab])  

  return (
    <section>
      <div className="m-16 max-w-6xl mx-auto px-4 sm:px-6">
          {/* Section header */}
          <div id="proceso" className="max-w-3xl mx-auto text-center pb-12" data-aos-id-tabs>
            <h2 className="h2 mb-4 text-green-100 title-apium" data-aos="fade-up" data-aos-anchor="[data-aos-id-tabs]">Como conseguimos los objetivos</h2>
          </div>

  {/* Section content */}
          <div>
  {/* Tabs buttons */}
  <div className="flex flex-wrap justify-center -m-2" data-aos="fade-up" data-aos-delay="400" data-aos-anchor="[data-aos-id-tabs]">
    <button className={`flex items-center font-medium py-2 px-4 m-2 bg-gray-800 rounded-full group transition duration-500 ${tab !== 1 && 'opacity-50'}`} onClick={() => setTab(1)}>
        <svg className="w-4 h-4 fill-current text-green-100 mr-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.5 5.5c-.311.001-.62.061-.909.177l-2.268-2.268c.116-.29.176-.598.177-.909a2.5 2.5 0 00-5 0c.001.311.061.62.177.909L3.409 5.677A2.473 2.473 0 002.5 5.5a2.5 2.5 0 000 5c.311-.001.62-.061.909-.177l2.268 2.268c-.116.29-.176.598-.177.909a2.5 2.5 0 105 0 2.473 2.473 0 00-.177-.909l2.268-2.268c.29.116.598.176.909.177a2.5 2.5 0 100-5zM8 11c-.311.001-.62.061-.909.177L4.823 8.909a2.423 2.423 0 000-1.818l2.268-2.268a2.423 2.423 0 001.818 0l2.268 2.268a2.423 2.423 0 000 1.818l-2.268 2.268A2.473 2.473 0 008 11z" />
        </svg>
        <span className="text-gray-400 group-hover:text-purple-200 transition-colors duration-150 ease-in-out"><span class="text-green-100 font-bold">Fase 01.</span> Prueba de admisión</span>
    </button>
    
    <button className={`flex items-center font-medium py-2 px-4 m-2 bg-gray-800 rounded-full group transition duration-500 ${tab !== 2 && 'opacity-50'}`}
   onClick={() => setTab(2)}>
        <svg className="w-4 h-4 fill-current text-green-100 mr-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.5 5.5c-.311.001-.62.061-.909.177l-2.268-2.268c.116-.29.176-.598.177-.909a2.5 2.5 0 00-5 0c.001.311.061.62.177.909L3.409 5.677A2.473 2.473 0 002.5 5.5a2.5 2.5 0 000 5c.311-.001.62-.061.909-.177l2.268 2.268c-.116.29-.176.598-.177.909a2.5 2.5 0 105 0 2.473 2.473 0 00-.177-.909l2.268-2.268c.29.116.598.176.909.177a2.5 2.5 0 100-5zM8 11c-.311.001-.62.061-.909.177L4.823 8.909a2.423 2.423 0 000-1.818l2.268-2.268a2.423 2.423 0 001.818 0l2.268 2.268a2.423 2.423 0 000 1.818l-2.268 2.268A2.473 2.473 0 008 11z" />
        </svg>
      <span className="text-gray-400 group-hover:text-gray-200 transition-colors duration-150 ease-in-out"><span class="text-green-100 font-bold">Fase 02.</span>  Pre-work</span>
    </button>

  <button
    className={`flex items-center font-medium py-2 px-4 m-2 bg-gray-800 rounded-full group transition duration-500 ${tab !== 3 && 'opacity-50'}`}
    onClick={() => setTab(3)}>
    <svg className="w-4 h-4 fill-current text-green-100 mr-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.5 5.5c-.311.001-.62.061-.909.177l-2.268-2.268c.116-.29.176-.598.177-.909a2.5 2.5 0 00-5 0c.001.311.061.62.177.909L3.409 5.677A2.473 2.473 0 002.5 5.5a2.5 2.5 0 000 5c.311-.001.62-.061.909-.177l2.268 2.268c-.116.29-.176.598-.177.909a2.5 2.5 0 105 0 2.473 2.473 0 00-.177-.909l2.268-2.268c.29.116.598.176.909.177a2.5 2.5 0 100-5zM8 11c-.311.001-.62.061-.909.177L4.823 8.909a2.423 2.423 0 000-1.818l2.268-2.268a2.423 2.423 0 001.818 0l2.268 2.268a2.423 2.423 0 000 1.818l-2.268 2.268A2.473 2.473 0 008 11z" />
    </svg>
    <span className="text-gray-400 group-hover:text-gray-200 transition-colors duration-150 ease-in-out"><span class="text-green-100 font-bold">Fase 03.</span> Curso</span>
  </button>

    <button
      className={`flex items-center font-medium py-2 px-4 m-2 bg-gray-800 rounded-full group transition duration-500 ${tab !== 4 && 'opacity-50'}`}
      onClick={() => setTab(4)}>
    <svg className="w-4 h-4 fill-current text-green-100 mr-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.5 5.5c-.311.001-.62.061-.909.177l-2.268-2.268c.116-.29.176-.598.177-.909a2.5 2.5 0 00-5 0c.001.311.061.62.177.909L3.409 5.677A2.473 2.473 0 002.5 5.5a2.5 2.5 0 000 5c.311-.001.62-.061.909-.177l2.268 2.268c-.116.29-.176.598-.177.909a2.5 2.5 0 105 0 2.473 2.473 0 00-.177-.909l2.268-2.268c.29.116.598.176.909.177a2.5 2.5 0 100-5zM8 11c-.311.001-.62.061-.909.177L4.823 8.909a2.423 2.423 0 000-1.818l2.268-2.268a2.423 2.423 0 001.818 0l2.268 2.268a2.423 2.423 0 000 1.818l-2.268 2.268A2.473 2.473 0 008 11z" />
        </svg>
      <span className="text-gray-400 group-hover:text-gray-200 transition-colors duration-150 ease-in-out"><span class="text-green-100 font-bold">Fase 04.</span>  Demo Day</span>
    </button>
            
</div>

    {/* Tabs items */}
<div className="relative flex flex-col mt-16" data-aos="fade-up" ref={tabs}>
    
{/* Item 1 */}
    <Transition
      show={tab === 1}
      appear={true}
      className="w-full"
      enter="transition ease-in-out duration-500 transform order-first"
      enterStart="opacity-0 scale-98"
      enterEnd="opacity-100 scale-100"
      leave="transition ease-out duration-300 transform absolute"
      leaveStart="opacity-100 scale-100"
      leaveEnd="opacity-0 scale-98"
    >
   <article className="relative max-w-md mx-auto md:max-w-none">

    <figure className="md:absolute md:inset-y-0 md:right-0 md:w-1/2">
       <img className="w-full h-full object-cover" src={require('../images/bootcamp-tile-icon-01.svg')} width="516" height="387" alt="Tabs 01" />
     </figure>

    <div className="relative py-8 md:py-16 px-6 md:pr-16 md:max-w-lg lg:max-w-xl">
        <h4 className="font-architects-daughter text-xl text-green-100">Cribado</h4>
        <h3 className="h3 mb-2 title-apium text-green-100 font-bold">FASE 1</h3>
        <h4 className="h4 title-apium text-green-200 font-bold mb-2">Prueba de admisión</h4>
        <p className="text-xl text-gray-400">Queremos asegurarnos de que vas a poder aprovechar el curso. No te preocupes, en caso de no pasar las pruebas, te ayudamos a conseguirlo para el siguiente curso.</p>
    </div>
    
 </article>
</Transition>

     {/* Item 2 */}
     <Transition
       show={tab === 2}
       appear={true}
       className="w-full"
       enter="transition ease-in-out duration-500 transform order-first"
       enterStart="opacity-0 scale-98"
       enterEnd="opacity-100 scale-100"
       leave="transition ease-out duration-300 transform absolute"
       leaveStart="opacity-100 scale-100"
       leaveEnd="opacity-0 scale-98"
     >
     <article className="relative max-w-md mx-auto md:max-w-none">
       <figure className="md:absolute md:inset-y-0 md:left-0 md:w-1/2">
         <img className="w-full h-full object-cover" src={require('../images/bootcamp-tile-icon-02.svg')} width="516" height="387" alt="Tabs 02" />
       </figure>

       <div className="relative  py-8 md:py-16 px-6 md:pl-16 md:max-w-lg lg:max-w-xl md:ml-auto">
         <h4 className="font-architects-daughter text-xl text-green-100">Preparación</h4>
         <h3 className="h3 mb-2 title-apium text-green-100 font-bold">FASE 2</h3>
         <h4 className="h4 title-apium text-green-200 font-bold mb-2">Actividades preparatorias desde casa</h4>
         <p className="text-lg text-gray-400">Sabemos que son necesarias 80 horas lectivas (2 semanas aprox) para darte esa agilidad necesaria y absorver todos los conocimientos del curso</p>
       </div>
    </article>
     </Transition>

  {/* Item 3 */}
    <Transition
      show={tab === 3}
      appear={true}
      className="w-full"
      enter="transition ease-in-out duration-500 transform order-first"
      enterStart="opacity-0 scale-98"
      enterEnd="opacity-100 scale-100"
      leave="transition ease-out duration-300 transform absolute"
      leaveStart="opacity-100 scale-100"
      leaveEnd="opacity-0 scale-98">
    
     <article className="relative max-w-md mx-auto md:max-w-none">
      <figure className="md:absolute md:inset-y-0 md:right-0 md:w-1/2">
       <img className="w-full h-full object-cover" src={require('../images/bootcamp-tile-icon-03.svg')} width="516" height="387" alt="Tabs 01" />
     </figure>

       <div className="relative  py-8 md:py-16 px-6 md:pr-16 md:max-w-lg lg:max-w-xl">
        <h4 className="font-architects-daughter text-xl text-green-100">Curso</h4>
        <h3 className="h3 mb-2 title-apium text-green-100 font-bold">FASE 3</h3>
        <h4 className="h4 title-apium text-green-200 font-bold mb-2">10 semanas, 100% presencial <span class="block">9h a 17h de Lunes a Viernes</span></h4>
        <p className="text-lg text-gray-400">Empezamos con el curso. Siendo un curso presencial, hemos activado unos protocolos muy estrictos para dar el curso con total seguridad en estos tiempos tan duros por la pandemia.</p>
      </div>
     </article>
      </Transition>

{/* Item 4 */}
     <Transition
       show={tab === 4}
       appear={true}
       className="w-full"
       enter="transition ease-in-out duration-500 transform order-first"
       enterStart="opacity-0 scale-98"
       enterEnd="opacity-100 scale-100"
       leave="transition ease-out duration-300 transform absolute"
       leaveStart="opacity-100 scale-100"
       leaveEnd="opacity-0 scale-98"
     >
     <article className="relative max-w-md mx-auto md:max-w-none">
       <figure className="md:absolute md:inset-y-0 md:left-0 md:w-1/2">
         <img className="w-full h-full object-cover" src={require('../images/bootcamp-tile-icon-04.svg')} width="516" height="387" alt="Tabs 02" />
       </figure>
       <div className="relative  py-8 md:py-16 px-6 md:pl-16 md:max-w-lg lg:max-w-xl md:ml-auto">
         <h4 className="font-architects-daughter text-xl text-green-100">Graduación</h4>
         <h3 className="h3 mb-2 title-apium text-green-100 font-bold">FASE 4</h3>
         <h4 className="h4 title-apium text-green-200 font-bold mb-2">Demo day con los contratadores</h4>
         <p className="text-lg text-gray-400">Demostraremos lo que hemos aprendido y pondremos en valor el gran logro que representa reinventarse y afrontar con ilusión un futuro lleno de oportunidades</p>
       </div>
     </article>
    </Transition>

   {/* Item 5 */}
   <Transition
     show={tab === 5}
     appear={true}
     className="w-full"
     enter="transition ease-in-out duration-500 transform order-first"
     enterStart="opacity-0 scale-98"
     enterEnd="opacity-100 scale-100"
     leave="transition ease-out duration-300 transform absolute"
     leaveStart="opacity-100 scale-100"
     leaveEnd="opacity-0 scale-98"
   >
     <article className="relative max-w-md mx-auto md:max-w-none">
       <figure className="md:absolute md:inset-y-0 md:right-0 md:w-1/2">
         <img className="w-full h-full object-cover" src={require('../images/tabs-image-01.jpg')} width="516" height="387" alt="Tabs 01" />
       </figure>
       <div className="relative bg-gray-800 py-8 md:py-16 px-6 md:pr-16 md:max-w-lg lg:max-w-xl">
         <h4 className="h4 mb-2 title-tab-apium" >Optimize and scale, easy to start</h4>
         <p className="text-lg text-gray-400">Este es el verdadero principio. Es donde vamos a crecer juntos aportando los servicios desde una perspectiva de largo plazo.</p>
       </div>
     </article>
   </Transition>

  {/* Item 6 */}
       <Transition
         show={tab === 6}
         appear={true}
         className="w-full"
         enter="transition ease-in-out duration-500 transform order-first"
         enterStart="opacity-0 scale-98"
         enterEnd="opacity-100 scale-100"
         leave="transition ease-out duration-300 transform absolute"
         leaveStart="opacity-100 scale-100"
         leaveEnd="opacity-0 scale-98"
       >
   <article className="relative max-w-md mx-auto md:max-w-none">
     <figure className="md:absolute md:inset-y-0 md:right-0 md:w-1/2">
       <img className="w-full h-full object-cover" src={require('../images/tabs-image-01.jpg')} width="516" height="387" alt="Tabs 01" />
     </figure>
     <div className="relative bg-gray-800 py-8 md:py-16 px-6 md:pr-16 md:max-w-lg lg:max-w-xl">
       <h4 className="h4 mb-2 text-green-100 title-tab-apium" >Bootcamp</h4>
       <p className="text-lg text-gray-400">Este es el verdadero principio. Es donde vamos a crecer juntos aportando los servicios desde una perspectiva de largo plazo.</p>
     </div>
   </article>
       </Transition>

</div>
</div>
</div>
    </section>
  );
}

export default RoadMap;
