import React from 'react';

function CaseStudies() {
  return (
    <section>
      <div id="programa" className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-800">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2 title-apium text-green-100 mb-4">Programa</h2>
            <p className="text-xl text-gray-400"><span className="font-bold text-green-100">6 Pasos</span> en <span className="font-bold text-green-100">10 semanas</span> para acceder en un mercado laboral en constante crecimiento.</p>
          </div>

 {/* Articles */}
       <div className="max-w-sm mx-auto grid gap-8 md:grid-cols-3 md:gap-6 lg:gap-6 items-start md:max-w-none">

  {/* 1st article */}
    <article className="flex flex-col " data-aos="fade-up">
      <div className="flex-grow flex flex-col h-full p-6 bg-gray-800">
        <h4 className="h4 mb-2 font-architects-daughter text-xl text-green-100">Conocimientos básicos</h4>
        <h3 className="h4 mb-2 title-bootcamp text-green-500 leading-tight"><span class="block text-green-100 font-bold">Bloque 1 </span>Fundamentos de programación</h3>
        <p className="text-base text-gray-400 flex-grow">Aprenderas las bases y los fundamentos de la lógica de programación así como el funcionamiento de las herramientas de desarrollo. Cómo funcionan los algoritmos y cuales son las estructuras de software que vas a utilizar.</p>
      </div>
    </article>

     {/* 2st article */}
   <article className="flex flex-col " data-aos="fade-up">
      <div className="flex-grow flex flex-col h-full p-6 bg-gray-800">
        <h4 className="h4 mb-2 font-architects-daughter text-xl text-green-100">Front-End</h4>
        <h3 className="h4 mb-2 title-bootcamp text-green-500 leading-tight"><span class="block text-green-100 font-bold">Bloque 2 </span>Programación front-end</h3>
        <p className="text-base text-gray-400 flex-grow">Nos metemos de lleno en la programación de la parte web de un producto digital. Trabajaremos con ReactJS y typescript, adaptando templates existentes, conectando con servicios externos.</p>
      </div>
    </article>

      {/* 3st article */}
    <article className="flex flex-col " data-aos="fade-up">
      <div className="flex-grow flex flex-col h-full p-6 bg-gray-800">
        <h4 className="h4 mb-2 font-architects-daughter text-xl text-green-100">Back-End</h4>
        <h3 className="h4 mb-2 title-bootcamp text-green-500 leading-tight"><span class="block text-green-100 font-bold">Bloque 3 </span>Programación back-end</h3>
        <p className="text-base text-gray-400 flex-grow">Construir un API en NodeJS para que pueda ser usada desde la aplicación front-end. Trabajo con librerías existentes. Este api seguirá los estándares en materia de seguridad, escalabilidad y adaptabilidad.</p>
      </div>
    </article>


    {/* 4st article */}
    <article className="flex flex-col " data-aos="fade-up">
      <div className="flex-grow flex flex-col h-full p-6 bg-gray-800">
        <h4 className="h4 mb-2 font-architects-daughter text-xl text-green-100">Monorepo</h4>
        <h3 className="h4 mb-2 title-bootcamp text-green-500 leading-tight"><span class="block text-green-100 font-bold">Bloque 4 </span>Desarrollo de tu primera aplicación</h3>
        <p className="text-base text-gray-400 flex-grow">Desarrollo completo de una aplicación online.</p>
      </div>
    </article>

     {/* 5st article */}
   <article className="flex flex-col " data-aos="fade-up">
      <div className="flex-grow flex flex-col h-full p-6 bg-gray-800">
        <h4 className="h4 mb-2 font-architects-daughter text-xl text-green-100">Trabajo en equipo</h4>
        <h3 className="h4 mb-2 title-bootcamp text-green-500 leading-tight"><span class="block text-green-100 font-bold">Bloque 5 </span>Desarrollo ágil en equipos distribuidos</h3>
        <p className="text-base text-gray-400 flex-grow">No solo codificar va a ser necesario para que te puedas integrar en un equipo. Es necesario conocer la metodología Agile y también las herramientas más comunes.</p>
      </div>
    </article>

    {/* 6st article */}
   <article className="flex flex-col " data-aos="fade-up">
      <div className="flex-grow flex flex-col h-full p-6 bg-gray-800">
        <h4 className="h4 mb-2 font-architects-daughter text-xl text-green-100">Plan de carrera</h4>
        <h3 className="h4 mb-2 title-bootcamp text-green-500 leading-tight"><span class="block text-green-100 font-bold">Bloque 6 </span>Preparación del perfil profesional</h3>
        <p className="text-base text-gray-400 flex-grow">Aprenderas las bases y los fundamentos de la lógica de programación así como el funcionamiento de las herramientas de desarrollo. Cómo funcionan los algoritmos y cuales son las estructuras de software que vas a utilizar.</p>
      </div>
    </article>


          </div>

        </div>
      </div>
    </section>
  );
}

export default CaseStudies;
