import React from 'react';

import PageIllustration from '../../partials/PageIllustration';
import Settings from "../../lib/settings";

function Apiumuser() {

    const logout = () => {
        Settings.logout().then(() => {
            document.location.href = "/";
        });
    }

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Page content */}
      <main className="flex-grow">

        {/*  Page illustration */}
        <div className="relative max-w-6xl mx-auto h-0 pointer-events-none" aria-hidden="true">
          <PageIllustration />
        </div>

          <section className="relative">
              <div className="max-w-6xl mx-auto px-4 sm:px-6">
                  <div className="pt-32 pb-12 md:pt-40 md:pb-20">
                      <h1>ESTAMOS</h1>
                      <h1>DESARROLLANDO ESTA PARTE</h1>
                      <h1>TE TENDREMOS INFORMADO</h1>

                      <div className="flex flex-wrap -mx-3 mt-6">
                          <div className="w-full px-3">
                              <button className="btn text-gray-900 bg-green-100 hover:bg-green-200 hover:text-green-100 w-full" onClick={logout}>SALIR</button>
                          </div>
                      </div>
                  </div>
              </div>
          </section>

      </main>

    </div>
  );
}

export default Apiumuser;
