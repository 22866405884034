import React, {useEffect, useState} from 'react';
import Modal from "../shared/utils/Modal";
import ClaimBootcamp from "./ClaimBootcamp";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../store/store";
import {SessionState, updateSession} from "../store/sessionStore";

const HeroClaim = () => {

    const ready = useSelector((state: AppState) => state.session.ready);
    const [videoModalOpen, setVideoModalOpen] = useState(false);
    const dispatch = useDispatch();
    const viewedState: SessionState = {loggedIn: false, ready: true}

    useEffect(()=>{
        setVideoModalOpen(!ready);
    },[]);

  return ( <>

          <Modal id="modal" ariaLabel="modal-headline" show={videoModalOpen} handleClose={() => {setVideoModalOpen(false); dispatch(updateSession(viewedState))}}>
              <div className="relative pb0">
                  <ClaimBootcamp/>
              </div>
          </Modal>
      </>
  );
}

export default HeroClaim;
