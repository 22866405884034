import React from 'react';
import {Link, useHistory} from 'react-router-dom';
import Header from '../partials/Header';
import PageIllustration from '../partials/PageIllustration';
import ApiCaller from "../lib/ApiCaller";
import AuthApi from "../api/AuthApi";
import toast from "../shared/utils/toast";


const SignUp = () => {

  const [name, setName] = React.useState<string>();
  const [email, setEmail] = React.useState<string>();
  const [password, setPassword] = React.useState<string>();
  const history = useHistory();

  const processForm = (e: React.FormEvent) => {
    console.log('into process form');
    var authApi = new AuthApi(new ApiCaller());
    if(!name || !email || !password){
      toast.error('Por favor, seleccione los campos');
      return;
    }
    authApi.register(email, password, name).then((data: any) => {
      history.push('/registered');
      return;
    }).catch(err => {
      console.error(err);
      toast.error('Error creating user, please try again');
    });
    e.preventDefault();
  }

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">

        {/*  Page illustration */}
        <div className="relative max-w-6xl mx-auto h-0 pointer-events-none" aria-hidden="true">
          <PageIllustration />
        </div>

        <section className="relative">
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-32 pb-12 md:pt-40 md:pb-10">

              {/* Page header */}
              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-10">
                <h1 className="h1">Bienvenido. Existimos para hacértelo más fácil.</h1>
              </div>

              {/* Form */}
              <div className="max-w-sm mx-auto">
                <form onSubmit={processForm}>
                  <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="full-name">Nombre Completo <span className="text-red-600">*</span></label>
                      <input id="full-name" type="text" className="form-input w-full text-gray-300" placeholder="First and last name" required  value={name} onChange={e => {setName(e.target.value)}}/>
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="company-name">Tu empresa</label>
                      <input id="company-name" type="text" className="form-input w-full text-gray-300" placeholder="Si no tienes deja en blanco este campo" required />
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="email">Tu email <span className="text-red-600">*</span></label>
                      <input id="email" type="email" className="form-input w-full text-gray-300" placeholder="you@yourcompany.com" required  value={email} onChange={e => {setEmail(e.target.value)}}/>
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="password">Password <span className="text-red-600">*</span></label>
                      <input id="password" type="password" className="form-input w-full text-gray-300" placeholder="Password (al menos 10 caracteres)" required value={password} onChange={e => {setPassword(e.target.value)}}/>
                    </div>
                  </div>
                  <div className="text-sm text-gray-500 text-center">
                    Accedo a que apiumtalent me contacte por temas de trabajo y mi carrera profesional <Link to="#" className="underline text-gray-400 hover:text-gray-200 hover:no-underline transition duration-150 ease-in-out">Política de privacidad</Link>.
                                </div>
                  <div className="flex flex-wrap -mx-3 mt-6">
                    <div className="w-full px-3">
                      <button  type="submit" className="btn text-white bg-purple-600 hover:bg-purple-700 w-full" >Regístrate</button>
                    </div>
                  </div>
                </form>
                <div className="text-gray-400 text-center mt-6">
                  ¿Ya tienes cuenta en apiumtalent? <Link to="signin" className="text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out">Accede</Link>
                </div>
              </div>

            </div>
          </div>
        </section>

      </main>

    </div>
  );
}

export default SignUp;
