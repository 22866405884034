import React from 'react';

import Header from '../partials/Header';
import PageIllustration from '../partials/PageIllustration';
import HeroHome from '../partials/HeroHome';
import Process from '../partials/Process';
import Tabs from '../partials/Tabs';
import Target from '../partials/Target';
import Cta from '../partials/Cta';
import Footer from '../partials/Footer';
import HeroClaim from "../partials/HeroClaim";

const Home = () => {

  return (<>
          <div className="flex flex-col min-h-screen overflow-hidden">
            <Header />
            <main className="flex-grow">

              {/*  Page illustration */}
              <div className="relative max-w-6xl mx-auto h-0 pointer-events-none" aria-hidden="true">
                <PageIllustration />
                <HeroClaim/>
              </div>

              {/*  Page sections */}
              <HeroHome />
              <Process />
              <Tabs />
              <Target />
              <Cta />

            </main>
            <Footer />
          </div>

      </>
  );
}

export default Home;
