import React from 'react';
import {Link, useHistory} from 'react-router-dom';

import Header from '../partials/Header';
import PageIllustration from '../partials/PageIllustration';
import {useDispatch} from "react-redux";
import {SessionState, updateSession} from "../store/sessionStore";
import ApiCaller from "../lib/ApiCaller";
import AuthApi from "../api/AuthApi";
import Settings from "../lib/settings";
import User from '../lib/common/models/user';

const SignIn = () => {

  const dispatch = useDispatch();
  const inputs = ['email', 'password'];
  const [email, setEmail] = React.useState<string>();
  const [password, setPassword] = React.useState<string>();
  const history = useHistory();

  const doAuth = (e: React.FormEvent) => {
    e.preventDefault();

    if(!email || !password) {
      return;
    }

    var authApi = new AuthApi(new ApiCaller());
    authApi.login(email, password).then((data:any) => {
      if(data.status != "confirmed") {
        history.push('/registered');
        return;
      }
      let user = new User(data.name, data.email, data.token, data.type, data.id);
      user.id=data.id;
      const sessionData:SessionState = {
        loggedIn: user.email? true : false,
        ready: true,
        userInfo: user
      };
      dispatch(updateSession(sessionData));

      Settings.setCurrentUser(user).then(() => {
        history.push("/"+data.type);
      });

    }).catch(err => {
      console.error(err);
      alert('Usuario o contraseña incorrectos');
    });
    e.preventDefault();
  }

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">

        {/*  Page illustration */}
        <div className="relative max-w-6xl mx-auto h-0 pointer-events-none" aria-hidden="true">
          <PageIllustration />
        </div>

        <section className="relative">
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">

              {/* Page header */}
              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                <h1 className="h1">Bienvenido de nuevo. Existimos para hacerte la vida más fácil.</h1>
              </div>

              {/* Form */}
              <div className="max-w-sm mx-auto">
                <form onSubmit={doAuth}>
                  <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="email">Email</label>
                      <input id="email" type="email" className="form-input w-full text-gray-300" placeholder="you@yourcompany.com" required  value={email} onChange={e => {setEmail(e.target.value)}}/>
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="password">Password</label>
                      <input id="password" type="password" className="form-input w-full text-gray-300" placeholder="Password (at least 10 characters)" required  value={password} onChange={e => {setPassword(e.target.value)}}/>
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <div className="flex justify-between">
                        <label className="flex items-center">
                          <input type="checkbox" className="form-checkbox" />
                          <span className="text-gray-400 ml-2">Mantenme conectado</span>
                        </label>
                        <Link to="/reset-password" className="text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out">¿Olvidaste el password?</Link>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mt-6">
                    <div className="w-full px-3">
                      <button className="btn text-white bg-purple-600 hover:bg-purple-700 w-full" type="submit"  >Accede</button>
                    </div>
                  </div>
                </form>
                <div className="text-gray-400 text-center mt-6">
                  Todavía no tienes cuenta <Link to="/signup" className="text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out">Regístrate</Link>
                </div>
              </div>

            </div>
          </div>
        </section>

      </main>

    </div>
  );
}

export default SignIn;
