import { spacing } from 'material-ui/styles';
import React from 'react';

import AppointmentApi from "../api/AppointmentApi";
import ApiCaller from "../lib/ApiCaller";
import HeroLandingForm from './HeroLandingForm';

const HeroLanding  = () => {

  return (
    <section className="relative md:pt-20 md:pb-20">

      <div className="absolute inset-0">
        <img className="w-full h-full object-cover" src={require('../images/alex-kotliarskyi-ourQHRTE2IM-unsplash.jpg')} width="1440" height="394" alt="About" />
        <div className="absolute inset-0 bg-gray-800 opacity-75" aria-hidden="true"></div>
      </div>

        <div className="md:grid md:grid-cols-12 md:gap-1 items-center">
            <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-4 lg:col-span-4 md:mb-0 md:order-1">
                <div className="max-w-4xl mx-auto px-4 sm:px-6 relative ">
                    <div className="pt-32 pb-12 md:pt-40 md:pb-0">
                        <div className="max-w-3xl mx-auto text-center">
                            <HeroLandingForm/>
                        </div>
                    </div>
                </div>
            </div>
<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-7 ml-8">                        
    <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
            <div className="max-w-3xl mx-auto text-center">
                <h1 className="h1 title-apium text-green-100 text-left  mb-4" data-aos="fade-up">¡Aprende a programar productos digitales y da un salto en tu carrera!</h1>
                <h3 className="h3 title-apium text-gray-300 text-left  mb-4" data-aos="fade-up">Capacítate como desarrollador web full-stack en 10 semanas, aprende a programar productos digitales</h3>
                <h4 className="text-3xl font-bold text-red-100 text-left  mb-4" data-aos="fade-up">< a src="https://static.tildacdn.com/tild3463-3832-4765-b064-653266306534/calendar_w.svg">Convocatoria / Mayo 2023</a></h4>
                <p className="text-xl text-left space-xl mb-4" data-aos="fade-up"> ✅  10 Semanas <span className="ml-4">✅ Online/Presencial </span>  <span className="ml-4">✅ Bolsa de empleo </span></p>
            </div>
        </div>
    </div>
</div>
        </div>

    </section>
  );
}

export default HeroLanding ;
