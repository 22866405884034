import React from 'react';

const BootcampProcess = () => {

  const items = [
      {block:'Fase 1', blockTitle:'Cribado', title: 'Prueba de admisión',
        description: 'Queremos asegurarnos de que vas a poder aprovechar el curso. No te preocupes, en caso de no pasar las pruebas, te ayudamos a conseguirlo para el siguiente curso'},
      {block:'Fase 2', blockTitle:'Preparación', title: 'Actividades preparatorias desde casa',
        description: 'Sabemos que son necesarias 80 horas lectivas (2 semanas aprox) para darte esa agilidad necesaria y absorver todos los conocimientos del curso'},
      {block:'Fase 3', blockTitle:'Curso', title: '10 semanas, 100% presencial - 9h a 17h de Lunes a Viernes',
        description: 'Empezamos con el curso. Siendo un curso presencial, hemos activado unos protocolos muy estrictos para dar el curso con total seguridad en estos tiempos tan duros por la pandemia.'},
      {block:'Fase 4', blockTitle:'Graduación', title: 'Demo day con los contratadores',
        description: 'Demostraremos lo que hemos aprendido y pondremos en valor el gran logro que representa reinventarse y afrontar con ilusión un futuro lleno de oportunidades'}
      ];

  return (

    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-800">
        <div className="py-12 md:py-20">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h1 title-apium text-green-100 mb-4">Como conseguimos los objetivos</h2>
              <p className="text-xl text-gray-400">Hemos diseñado <span className="text-xl text-green-100">4 fases</span> bien diferenciadas</p>
          </div>

          {/* Items */}
          <div className="max-w-3xl mx-auto -my-4 md:-my-6" data-aos-id-timeline>

            {/* 1st item */}
            { items.map((i, index)=><div className="relative py-4 md:py-6 pl-24" data-aos="fade-up" data-aos-delay={index*200} data-aos-anchor="[data-aos-id-timeline]">
              <div className="pl-2">
                <div className="font-architects-daughter text-xl text-green-100 mb-2">{i.blockTitle}</div>
                <div className="flex items-center mb-3">
                  <div className="absolute left-0 inline-flex text-sm font-semibold py-1 px-3 text-gray-900 bg-green-200 rounded-full">{i.block}</div>
                  <div className="absolute left-0 h-full px-px bg-green-100 ml-20 self-start transform -translate-x-1/2 translate-y-3" aria-hidden="true"></div>
                  <div className="absolute left-0 w-2 h-2 bg-green-100 border-4 box-content border-gray-900 rounded-full ml-20 transform -translate-x-1/2" aria-hidden="true"></div>
                  <h4 className="h4 title-apium text-green-100">{i.title}</h4>
                </div>
                <p className="text-lg text-gray-200">{i.description}</p>
              </div>
            </div>)}

          </div>

        </div>
      </div>
      </div>
    </section>
  );
}

export default BootcampProcess;
