interface NZDocumentInfo {
  id?: string;
  _id?: string;
  createdBy?: string;
  sourceSlug?: string;
  type: NZDocumentType;
  fileHash: string;
  mimeType: string;
  expirationDate?: Date;
  status?: NZDocumentStatus;
  displayName?: string;
  description?: string;
  createdAt?: Date;
  content?: string;
}

export enum NZDocumentType {
  GENERAL_PURPOSE = 'GENERAL_PURPOSE',
  LEGAL_DOCUMENT = 'LEGAL_DOCUMENT',
  CONTRACT = 'CONTRACT',
  OTHER = 'OTHER',
}

export enum NZDocumentStatus {
  REVOKED = 'REVOKED',
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
}

class NZDocument {
  id?: string;
  createdBy?: string;
  sourceSlug?: string;
  type: NZDocumentType;
  fileHash: string;
  mimeType: string;
  expirationDate?: Date;
  status?: NZDocumentStatus;
  displayName?: string;
  description?: string;
  createdAt?: Date;
  content?: string;

  constructor(file: NZDocumentInfo) {
    this.id = file._id;
    this.createdBy = file.createdBy;
    this.sourceSlug = file.sourceSlug;
    this.type = file.type;
    this.fileHash = file.fileHash;
    this.mimeType = file.mimeType;
    this.expirationDate = file.expirationDate;
    this.status = file.status;
    this.displayName = file.displayName;
    this.description = file.description;
    this.createdAt = file.createdAt;
    this.content = file.content;
  }

  toObject(): NZDocumentInfo {
    return {
      id: this.id,
      createdBy: this.createdBy,
      sourceSlug: this.sourceSlug,
      type: this.type,
      fileHash: this.fileHash,
      mimeType: this.mimeType,
      expirationDate: this.expirationDate,
      status: this.status,
      displayName: this.displayName,
      description: this.description,
      createdAt: this.createdAt,
      content: this.content,
    };
  }
}

export default NZDocument;
