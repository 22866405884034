import User from '../lib/common/models/user';

//------------  state definition
export interface SessionState {
    loggedIn: boolean,
    userInfo?: User,
    ready: boolean
}

//------------ actions
export const UPDATE_SESSION = 'UPDATE_SESSION'

interface UpdateSessionAction {
  type: typeof UPDATE_SESSION
  payload: SessionState
}

export function updateSession(newSession: SessionState): SessionActionTypes {
    return {
      type: UPDATE_SESSION,
      payload: newSession
    }
}

export type SessionActionTypes = UpdateSessionAction

//------------ reducer
const initialState: SessionState = {
  loggedIn: false,
  userInfo: new User("Invitado", "", "GUEST_TOKEN", "guest"),
  ready: false
}

export function sessionReducer( state = initialState, action: SessionActionTypes): SessionState {
  switch (action.type) {
    case UPDATE_SESSION: {
      return {
        ...state,
        ...action.payload
      }
    }
    default:
      return state
  }
}
