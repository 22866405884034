import React from 'react';

import pablo from "../images/pablo-petinari.jpg";
import marisa from "../images/marisa-colantuono.jpg";
import xavi from "../images/xavi-hidalgo.jpg";
import graham from "../images/graham-moran.jpg";

const Team = () =>  {

  const team = [
    {nom: 'Pablo Petinari', position: 'Operaciones', img: pablo, twitter: '@pablo'},
    {nom: 'Marisa Colantuono', position: 'Recursos Humanos', img: marisa, twitter: '@marisa'},
    {nom: 'Xavi Hidalgo', position: 'Plan de estudios', img: xavi, twitter: '@xavi' },
    {nom: 'Graham Moran', position: 'Consultoria', img: graham, twitter: '@graham' },
  ];

  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-800">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2 title-apium text-green-100 mb-4">Aprende de los mejores instructores</h2>
            <p className="text-xl text-gray-400">Dirigidos por un equipo con años en el sector de la docencia</p>
          </div>

          {/* Team members */}
          <div className="sm:flex sm:flex-wrap sm:justify-center -my-4 sm:-my-8 sm:-mx-3" data-aos-id-team>

            {/* 1st member */}
            { team.map((item,index)=>{
              return <div className="sm:w-1/2 md:w-1/3 lg:w-1/4 py-4 sm:py-8 sm:px-3" data-aos="fade-up" data-aos-delay={index*100} data-aos-anchor="[data-aos-id-team]">
                <div className="flex flex-col items-center">
                  <img className="rounded-full mb-4" src={item.img} width="120" height="120" alt="Team member 02" />
                  <h4 className="text-xl font-medium mb-1">{item.nom}</h4>
                  <div className="text-gray-500 mb-1">{item.position}</div>
                  <a className="block text-green-600 hover:text-gray-200 transition duration-150 ease-in-out" href="#" onClick={(e)=>{e.preventDefault();}}>{item.twitter}</a>
                </div>
              </div>
            })}



          </div>

        </div>
      </div>
    </section>
  );
}

export default Team;
