
interface DossierInfo {
  id?: string;
  _id?: string;
  createdBy?: string;
  expirationDate?: Date;

  name?: string;
  url?: string;
  description?: string;
  category?: DossierCategory;
  status?: DossierStatus;
  priority?: number;

  assignedTo?: string;
  laywers?: Array<Participants>
  customers?: Array<Participants>

  createdAt?: Date;
}

export interface Participants{
  id:string;
  name:string;
  email:string;
  confirmed:boolean;
}

export enum DossierCategory {
  GENERAL_PURPOSE = 'GENERAL_PURPOSE',
  LEGAL_DOCUMENT = 'document',
  DOSSIER = 'dossier',
  EXPRESS = 'express',
}

export enum DossierStatus {
  REVOKED = 'REVOKED',
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  DONE = 'DONE',
  TEST = 'TEST',
}

class Dossier {
  id?: string;
  createdBy?: string;
  expirationDate?: Date;

  name?: string;
  url?: string;
  description?: string;
  category?: DossierCategory;
  status?: DossierStatus;
  priority?: number;

  assignedTo?: string;
  laywers?: Array<Participants>
  customers?: Array<Participants>

  createdAt?: Date;

  constructor(file: DossierInfo) {
    this.id = file._id;
    this.createdBy = file.createdBy;
    this.expirationDate = file.expirationDate;
    this.status = file.status;
    this.name = file.name;
    this.description = file.description;
    this.category = file.category;
    this.status = file.status;
    this.createdAt = file.createdAt;
    this.url = file.url;
    this.laywers = file.laywers;
    this.customers = file.customers;
    this.priority = file.priority
  }

  toObject(): DossierInfo {
    return {
      id: this.id,
      createdBy: this.createdBy,
      category: this.category,
      expirationDate: this.expirationDate,
      status: this.status,
      url: this.url,
      name: this.name,
      description: this.description,
      createdAt: this.createdAt,
      laywers: this.laywers,
      customers: this.customers,
      priority: this.priority,
    };
  }
}

export default Dossier;
