import React, {useState} from 'react';

import PageIllustration from '../partials/PageIllustration';
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import Stats from "../partials/Stats";

const BootcampSponsors = () => {


  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

        <Header />

      <main className="flex-grow">

        <div className="relative max-w-6xl mx-auto h-0 pointer-events-none" aria-hidden="true">
          <PageIllustration />
        </div>

          <div className="relative md:pt-40 md:pb-20">
          <Stats/>
          </div>

      </main>

        <Footer />
    </div>
  );
}

export default BootcampSponsors;
