import Lend from './lend';

export interface LendsGroup {
    finished: LendsCollection,
    unfinished: LendsCollection
}

class LendsCollection {
    lends: Array<Lend>;

    constructor(lends: Array<Lend>) {
        this.lends = lends;
    }

    length() {
        return this.lends.length;
    }

    getSoonerReturn() {
        if (this.lends.length == 0) {
            return;
        }

        return this.lends.reduce((min, p) => p.date < min.date ? p : min, this.lends[0]);
    }

    orderedByReturnDate():LendsCollection {
        return new LendsCollection(this.lends.sort((a, b) => (parseInt(a.date) - parseInt(b.date))));
    }

    getLends() {
        return this.lends;
    }
}

export default LendsCollection