import React, { useState } from 'react';

import Header from '../partials/Header';
import PageIllustration from '../partials/PageIllustration';
import Footer from '../partials/Footer';

function Help() {

  const [page, setPage] = useState(1);

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">

        {/*  Page illustration */}
        <div className="relative max-w-6xl mx-auto h-0 pointer-events-none" aria-hidden="true">
          <PageIllustration />
        </div>

        {/*  Page sections */}
        <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
          <div className="pt-32 pb-12 md:pt-40 md:pb-20">
            <div className="flex flex-col md:flex-row">

              {/* Main content */}
              <main className="md:flex-auto md:pl-10 order-1" data-aos="fade-up">

                {/* FAQ’s – Frequently Asked Questions */}
                <div className={page !== 1 ? 'hidden' : undefined}>
                  <div className="mb-8">
                    <h2 className="h2 mb-4 text-purple-600 title-apium">FAQ’s – Preguntas frecuentes</h2>
                    <p className="text-gray-400">Última actualización - <span className="text-purple-600">Febrero 15, 2021</span></p>
                  </div>
                  <ul className="-my-4">
                    <li className="py-4">
                      <h4 className="text-xl font-medium mb-2 text-purple-600 title-apium">¿Para qué tipo de posiciones puedo contratar a APIUMTALENT?</h4>
                      <p className="text-lg text-gray-400">Nuestros servicios son ideales para contratación de los mejores PERFILES TECNOLÓGICOS
                      </p>
                 
                 <li className="flex items-center mb-2">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Si necesitas contratar a los mejores cuanto antes.</span>
                  </li>

                  <li className="flex items-center mb-2">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Remotos o In-house.</span>
                  </li>

                  <li className="flex items-center mb-2">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>En diferentes países o ciudades/ provincias/ estados específicos.</span>
                  </li>

                 <li className="flex items-center mb-2">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Para diferentes géneros o por necesidad puntual de TALENTO FEMENINO.</span>
                  </li>

 <li className="flex items-center mb-2">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Se puede, en caso de que sea necesario, apuntarlo a relocación de perfiles tecnológicos.</span>
                  </li>
                    </li>

                    <li className="py-4">
                      <h4 className="text-xl font-medium mb-2 text-purple-600 title-apium">¿A partir de cuántas posiciones puedo cubrir con vuestro servicio?</h4>
                      <p className="text-lg text-gray-400">Puedes utilizar nuestro servicio para cubrir entre 1-100 perfiles en simultáneo; o inclusive más.</p>
                    </li>
                    <li className="py-4">
                      <h4 className="text-xl font-medium mb-2 text-purple-600 title-apium">¿Cuál es la principal ventaja de contratar vuestros servicios?</h4>
                  <li className="flex items-center mb-2">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Rapidez: Puedes reclutar a tu equipo completo en 72 Hs.</span>
                  </li>

                   <li className="flex items-center mb-2">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Costos: En cualquier caso, será más económico que cualquier servicio de Headhunting tradicional.</span>
                  </li>

 <li className="flex items-center mb-2">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Versatilidad: Puedes elegir el servicio que mejor se adapta a tu necesidad real.</span>
                  </li>


 <li className="flex items-center mb-2">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Talent Branding: Reforzarás, también, tu estrategia de Talent Branding.</span>
                  </li>
 <li className="flex items-center mb-2">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Si utilizas Apium-Women: Puedes acelerar la inclusión de talento fememino en tu empresa, reclutando a aquellas mujeres que realmente hagan un buen fit en todo sentido.</span>
                  </li>
                    </li>

<li className="py-4">
                      <h4 className="text-xl font-medium mb-2 text-purple-600 title-apium">¿Cuándo me conviene contratar Apiumtalent-Apiumtech?</h4>
<p className="text-lg text-gray-400">Si Debes armar un proyecto desde cero y luego necesitas contar con el mejor personal tecnológico dentro de tu empresa. Este es tu servicio ideal; más económico, más práctico y más eficaz.</p>
                    </li>
                    <li className="py-4">
                      <h4 className="text-xl font-medium mb-2 text-purple-600 title-apium">¿Los servicios de Apiumtalent funcionan en todo el mundo?
</h4>
                      <p className="text-lg text-gray-400">Si,nuestros servicios funcionan por igual en todo el mundo. </p>
                    </li>
                    <li className="py-4">
                      <h4 className="text-xl font-medium mb-2 text-purple-600 title-apium">¿Cómo funciona la activación del servicio?</h4>
                      <p className="text-lg text-gray-400">Tú nos indicas qué servicio te interesa y completas los datos, te llegará una cotización adaptada a tu necesidad y nos comunicamos contigo. El mismo día puedes activar el servicio si lo deseas.</p>
                    </li>
                  </ul>
                </div>

{/* What is Open PRO */}
                <div className={page !== 2 ? 'hidden' : undefined}>
                  <div className="mb-8">
                    <h2 className="h2 text-purple-600 title-apium mb-4">FAQ’s – Preguntas frecuentes</h2>
                    <p className="text-gray-400">Last updated - <span className="text-purple-600">Febrero, 15 2021</span></p>
                  </div>
                  <ul className="-my-4">
                    <li className="py-4">
                      <h4 className="text-xl font-medium mb-2">How can I use Open PRO without registration?</h4>
                      <p className="text-lg text-gray-400">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis enim lobortis scelerisque fermentum.</p>
                    </li>
                    <li className="py-4">
                      <h4 className="text-xl font-medium mb-2">Can I import my sitemap to Open PRO?</h4>
                      <p className="text-lg text-gray-400">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis enim lobortis scelerisque fermentum.</p>
                    </li>
                    <li className="py-4">
                      <h4 className="text-xl font-medium mb-2">How can I switch my subscription between essential, and premium plans?</h4>
                      <p className="text-lg text-gray-400">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis enim lobortis scelerisque fermentum.</p>
                    </li>
                    <li className="py-4">
                      <h4 className="text-xl font-medium mb-2">Can I cancel my subscription at any time?</h4>
                      <p className="text-lg text-gray-400">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis enim lobortis scelerisque fermentum.</p>
                    </li>
                    <li className="py-4">
                      <h4 className="text-xl font-medium mb-2">Is there an additional discount when paid annually?</h4>
                      <p className="text-lg text-gray-400">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis enim lobortis scelerisque fermentum.</p>
                    </li>
                    <li className="py-4">
                      <h4 className="text-xl font-medium mb-2">What happens if I don’t renew my license after one year?</h4>
                      <p className="text-lg text-gray-400">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis enim lobortis scelerisque fermentum.</p>
                    </li>
                    <li className="py-4">
                      <h4 className="text-xl font-medium mb-2">What kind of payment methods do you provide?</h4>
                      <p className="text-lg text-gray-400">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis enim lobortis scelerisque fermentum.</p>
                    </li>
                  </ul>
                </div>

                


              </main>

              {/* Nav sidebar */}
              <aside className="md:w-64 mb-16 md:mb-0 md:mr-10 md:flex-shrink-0" data-aos="fade-up" data-aos-delay="200">
                <nav>
                  <ul>
                    <li className="py-2 border-b border-gray-800">
                      <a
                        className={`flex items-center px-3 group text-gray-400 hover:text-purple-600 transition duration-150 ease-in-out ${page === 1 && 'text-purple-600'}`}
                        href="#0"
                        onClick={(e) => { e.preventDefault(); setPage(1); }}
                      >
                        <span>Apiumtalent</span>
                        <svg className="w-3 h-3 fill-current flex-shrink-0 ml-2 opacity-0 group-hover:opacity-100 group-hover:text-purple-600 group-hover:translate-x-1 transition duration-150 ease-in-out transform" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" />
                        </svg>
                      </a>
                    </li>
                    <li className="py-2 border-b border-gray-800">
                      <a
                        className={`flex items-center px-3 group text-gray-400 hover:text-purple-600 transition duration-150 ease-in-out ${page === 2 && 'text-purple-600'}`}
                        href="#0"
                        onClick={(e) => { e.preventDefault(); setPage(2); }}
                      >
                        <span>Bootcamp</span>
                        <svg className="w-3 h-3 fill-current flex-shrink-0 ml-2 opacity-0 group-hover:opacity-100 group-hover:text-purple-600 group-hover:translate-x-1 transition duration-150 ease-in-out transform" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" />
                        </svg>
                      </a>
                    </li>
 
                    
                    
                  </ul>
                </nav>
              </aside>

            </div>
          </div>
        </div>

      </main>

      {/*  Site footer */}
      <Footer />

    </div>
  );
}

export default Help;