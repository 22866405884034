import React from 'react';

function FeaturesZigzag() {
  return (

<section>
    <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-800">
            
        {/* Section header 
            <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
                <div className="inline-flex text-sm font-semibold py-1 px-3 m-2 text-green-600 bg-green-200 rounded-full mb-4">Reach goals that matter</div>
                <h1 className="h2 mb-4 text-purple-600 title-apium">One product, unlimited solutions</h1>
                <p className="text-xl text-gray-400">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit laborum — semper quis lectus nulla.</p>
        </div>*/}

        {/* Items */}
            <div className="grid gap-20">

{/* 1st item */}
<div className="md:grid md:grid-cols-12 md:gap-6 items-center">
        {/* Image */}
        <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1" data-aos="fade-up">
           <img className="max-w-full mx-auto md:max-w-none h-auto" src={require('../images/features-01-05.png')} width="540" height="405" alt="Features 01" />
        </div>

{/* Content */}
<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6" data-aos="fade-right">
    <div className="md:pr-4 lg:pr-12 xl:pr-16">
        
        <div className="font-architects-daughter text-xl text-purple-600 mb-2">Aceleramos tu proceso</div>
        <h3 className="h3 mb-3 title-apium">Apiumtalent <span className="text-purple-600">AI</span></h3>
        <p className="text-xl text-gray-400 mb-4">Nuestra Inteligencia Artificial busca por ti y aceleramos tu proceso.</p>
   
<ul className="text-lg text-gray-400 -mb-2">

<li className="flex items-center mb-2">
        <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
        </svg>
        <span>Define tu perfil</span>
        </li>
<li className="flex items-center mb-2">
                            <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                            </svg>
                            <span>Nuestra Inteligencia Artificial busca por ti</span>
                         </li>

   <li className="flex items-center">
      <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
      </svg>
      <span>En entre 48 y 72 Hs, Tocarán a tu puerta candidatos 100% alineados interesados, solamente deberás agendar entrevistas con ellos, llegarán directamente al email de tu empresa</span>
   </li>
</ul>
</div>

{/* Button */}
<div className="border border-gray-700 p-3 mt-6">
<a className="btn-sm text-white bg-purple-600 hover:bg-purple-700 w-full" href="/empresas">Saber más</a>
</div>

</div>
</div>

{/* 2nd item */}
    <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
        {/* Image */}
                <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:rtl" data-aos="fade-up">
                        <img className="max-w-full mx-auto md:max-w-none h-auto" src={require('../images/features-02-05.png')} width="540" height="405" alt="Features 02" />
                </div>
        {/* Content */}
                    <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6" data-aos="fade-left">
                        <div className="md:pl-4 lg:pl-12 xl:pl-16">
                            <div className="font-architects-daughter text-xl text-purple-600 mb-2">Encontramos a tu equipo entre 72 y 96 Hs</div>
                            <h3 className="h3 mb-3 title-apium">Apiumtalent <span className="text-purple-600">360</span></h3>
                            <p className="text-xl text-gray-400 mb-4">Encontramos a tu equipo entre 72 y 96 Hs. Nos delegas al 100% la búsqueda de tu equipo o perfil (AI + Headhunting). Cuidamos tanto del fit cultural como del fit técnico y profesional.</p>
                            <ul className="text-lg text-gray-400 -mb-2">
                                <li className="flex items-center mb-2">
                                    <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                    </svg>
                                    <span>Relevamiento de perfil - Si no tienes Job Description, la armamos.</span>
                                </li>
<li className="flex items-center mb-2">
    <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
    </svg>

<span>Research específico de parte de nuestro equipo de headhunters expertos combinada con nuestra herramienta de Inteligencia Artificial.</span>
</li>

    <li className="flex items-center">
        <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
        </svg>
        <span>En entre 72 y 96 horas podrás resolver la necesidad completa, ya sea de selección de un solo perfil, como de un equipo completo.</span>
    </li>
    
    <li className="flex items-center">
        <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
        </svg>
        <span>Te llegarán al menos 3 perfiles 100% alineados, entrevistados, evaluados y preseleccionados por cada posición.</span>
    </li>
    </ul>

</div>

{/* Button */}
<div className="border border-gray-700 p-3 mt-6">
<a className="btn-sm text-white bg-purple-600 hover:bg-purple-700 w-full" href="/empresas">Saber más</a>
</div>

</div>
</div>

{/* 3rd item */}
    <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
{/* Image */}
    <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1" data-aos="fade-up">
    <img className="max-w-full mx-auto md:max-w-none h-auto" src={require('../images/features-03-05.png')} width="540" height="405" alt="Features 03" />
    </div>
{/* Content */}
    <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6" data-aos="fade-right">
        <div className="md:pr-4 lg:pr-12 xl:pr-16">
            <div className="font-architects-daughter text-xl text-purple-600 mb-2">Somos una extensión de tu empresa</div>
            <h3 className="h3 mb-3 title-apium">Talent <span className="text-purple-600">Ambassadors</span></h3>
<p className="text-xl text-gray-400 mb-4">Somos una extensión de tu empresa. Servicio ideal para startups en crecimiento o empresas con proyectos específicos. 
</p>
    <ul className="text-lg text-gray-400 -mb-2">
        <li className="flex items-center mb-2">
            <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
            </svg>
            <span>Resolvemos tu necesidad de recurso constante, sin que necesites agrandar tu equipo de Talent Acquisition o contar con uno.</span>
        </li>
        <li className="flex items-center mb-2">
            <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
            </svg>
            <span>Cubrirás la necesidad de contratación de cada recurso entre 72 y 96 hs; asegurándote de que sea un fit completo (Cultural y profesional).</span>
        </li>
        </ul>
            </div>

{/* Button */}
<div className="border border-gray-700 p-3 mt-6">
<a className="btn-sm text-white bg-purple-600 hover:bg-purple-700 w-full" href="/empresas">Saber más</a>
</div>
            </div>
            </div>

{/* 4nd item */}
<div className="md:grid md:grid-cols-12 md:gap-6 items-center">
    {/* Image */}
        <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:rtl" data-aos="fade-up">
            <img className="max-w-full mx-auto md:max-w-none h-auto" src={require('../images/features-04-05.png')} width="540" height="405" alt="Features 02" />
        </div>
    {/* Content */}
        <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6" data-aos="fade-left">
            <div className="md:pl-4 lg:pl-12 xl:pl-16">
            <div className="font-architects-daughter text-xl text-purple-600 mb-2">Identificamos el mejor talento femenino en tecnología</div>
        <h3 className="h3 mb-3 title-apium">Apiumtalent - <span className="text-purple-600">Womentech</span></h3>
<p className="text-xl text-gray-400 mb-4">Nuestra herramienta de Inteligencia Artificial se ocupa de identificar al mejor talento femenino en tecnología tanto a nivel global como regional o local. Podemos ayudarte a desarrollar tu inclusión y diversidad.
</p>
<p className="text-xl text-gray-400 mb-4">Si tu empresa intenta igualar la presencia de hombres y mujeres pero aún le cuesta mucho llegar y reclutar al talento femenino adecuado, con nuestra herramienta podrás llegar directamente a aquellas candidatas alineadas e interesadas en tiempo récord.</p>
<p className="text-xl text-gray-400 mb-4">Si tu necesidad de incorporación de talento femenino es permanente, tendrás la posibilidad de contar con una membresía mensual. En cualquier caso reducirás  significativamente tus esfuerzos, recursos y costos de reclutamiento; logrando resultados entre 72 y 96 Hs.
</p>
</div>

{/* Button */}
<div className="border border-gray-700 p-3 mt-6">
<a className="btn-sm text-white bg-purple-600 hover:bg-purple-700 w-full" href="/empresas">Saber más</a>
</div>
</div>
</div>

{/* 5rd item */}
    <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
{/* Image */}
    <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1" data-aos="fade-up">
    <img className="max-w-full mx-auto md:max-w-none h-auto" src={require('../images/features-04-05.png')} width="540" height="405" alt="Features 03" />
    </div>
{/* Content */}
    <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6" data-aos="fade-right">
        <div className="md:pr-4 lg:pr-12 xl:pr-16">
            <div className="font-architects-daughter text-xl text-purple-600 mb-2">Tu equipo 100% productivo desde el momento cero</div>
            <h3 className="h3 mb-3 title-apium">Apiumtalent & <span className="text-purple-600">Apiumtech</span></h3>
<p className="text-xl text-gray-400 mb-4">Cuando Apiumtech realiza tu proyecto, su equipo luego podrá ser el que continúe trabajando en tu compañía cuando lo necesites in-house.  </p>
<p className="text-xl text-gray-400 mb-4">Tu equipo será 100% productivo desde el momento cero en que trabaje in-house, conociendo el proyecto y habiendo sido evaluado previamente.</p>
    <ul className="text-lg text-gray-400 -mb-2">
        
        <li className="flex items-center mb-2">
            <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
            </svg>
            <span>Contratas a Apiumtech para tu proyecto de desarrollo de Software www.apiumtech.com</span>
        </li>
        <li className="flex items-center mb-2">
            <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
            </svg>
            <span>Reclutamos a tu equipo: En 72-96 hs.</span>
        </li>
        <li className="flex items-center mb-2">
            <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
            </svg>
            <span>Apiumtech lo capacita y evalúa a lo largo del proyecto.</span>
        </li>
 <li className="flex items-center mb-2">
            <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
            </svg>
            <span>Una vez finalizado el proyecto con Apiumtech, el equipo comienza a trabajar dentro de tu compañía, siendo 100% productivo desde el primer momento.</span>
        </li>

 </ul>
            </div>
{/* Button */}
<div className="border border-gray-700 p-3 mt-6">
<a className="btn-sm text-white bg-purple-600 hover:bg-purple-700 w-full" href="/empresas">Saber más</a>
</div>
            </div>
            </div>





         </div>
      </div>
    </div>
</section>
  );
}

export default FeaturesZigzag;