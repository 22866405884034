import LegalModel from '../lib/common/models/legalmodel';

//------------  state definition
export interface TemplateState {
    current: LegalModel
    ready: boolean
}

//------------ actions
export const SET_CURRENT_TEMPLATE = 'SET_CURRENT_TEMPLATE'

interface SetCurrentTemplateAction {
  type: typeof SET_CURRENT_TEMPLATE
  payload: LegalModel
}

export function addLends(legalModel: LegalModel): TemplatesActionTypes {
    return {
      type: SET_CURRENT_TEMPLATE,
      payload: legalModel
    }
}

export type TemplatesActionTypes = SetCurrentTemplateAction;

//------------ reducer
const initialState: TemplateState = {
    current: new LegalModel({title:'', langCode:'', slug:'', legalContent:{}, metadata:{}}) ,
    ready: false
}

export function templatesReducer( state = initialState, action: TemplatesActionTypes): TemplateState {
  switch (action.type) {
    case SET_CURRENT_TEMPLATE: {
      return {
        ...state,
        current: action.payload,
        ready: true
      }
    }
    default:
      return state
  }
}
