import React, {useState} from 'react';
import AppointmentApi from "../api/AppointmentApi";
import ApiCaller from "../lib/ApiCaller";
import {is} from "../shared/utils/validation";
import Modal from "../shared/utils/Modal";
import Thanks from "./Thanks";
import toast from "../shared/utils/toast";

const HeroLandingForm = () => {

    const [name,setName] = useState('');
    const [phone,setPhone] = useState('');
    const [email,setEmail] = useState('');
    const [check,setCheck] = useState(false);
    const [notSended,setnotSended] = useState(true);
    const [videoModalOpen, setVideoModalOpen] = useState(false);

    const sendEmail = async (e) => {
        e.preventDefault();
        const api = new AppointmentApi(new ApiCaller());
        const userId =  'anonymous'
        is.email(email) && console.log(is.email(email) )

        if (email==='' ||  name==='' || phone==='') {
            toast.error('Por favor, introduce los campos que faltan.');
            return;
        }
        if (!check) {
            toast.error('Por favor, acepta las condiciones.') ;
            return;
        }

        await api.sendRequest({type:'bootcamp-request', name:name, request:phone, email:email },userId).then((resp) => {
            setnotSended(false);
            setVideoModalOpen(true);
            toast.success('Genial! Ya te hemos enviado un email.')
        }).catch(err => {
            toast.error('Ya hemos enviado un email a esta cuenta.')
        });
    }

  return ( <>
      { notSended && <div>
<div className="max-w-3xl mx-auto text-left  pb-2 md:pb-6">
    <p className="text-2xl font-bold text-green-100" data-aos="fade-up">Completa el formulario de solicitud y contactaremos contigo para una entrevista.</p>

</div>
    
    <form className="bg-gray-300 bg-opacity-25 p-10 max-w-xl mx-auto">
        
        <div className="flex flex-wrap -mx-3 mb-4">
            <div className="w-full px-3">
                
                <label className="block text-green-100 text-left text-xl font-medium mb-1" htmlFor="email">Teléfono <span className="text-red-100">*</span></label>
                <input id="phone" type="phone" className="form-input w-full text-gray-300 focus:border-green-100" placeholder="Te llamaremos" required value={email} onChange={(e)=>{setEmail(e.target.value)}} />
            </div>
        </div>

       <div className="flex flex-wrap -mx-3 mb-4">

        <div className="w-full md:w-1/2 px-3">
            <label className="block text-green-100 text-left text-sm font-medium mb-1" htmlFor="last-name">Nombre <span className="text-red-100">*</span></label>
            <input id="name" type="text" className="form-input w-full text-gray-300  focus:border-green-100" placeholder="Nombre completo" required value={phone} onChange={(e)=>{setPhone(e.target.value)}} />
        </div>

        <div className="w-full md:w-1/2 text-left px-3 mb-4 md:mb-0">
            <label className="block text-green-100 text-sm font-medium mb-1" htmlFor="first-name">Email<span className="text-red-100">*</span></label>
            <input id="email" type="text" className="form-input w-full text-gray-100 focus:border-green-100" placeholder="Tu direccion email" required value={name} onChange={(e)=>{setName(e.target.value)}}/>
        </div>

        </div>

    <div className="flex flex-wrap -mx-3 mb-4">
        <div className="w-full px-3">
            <label className="flex items-center">
                <input type="checkbox" className="form-checkbox " onChange={(e)=>{setCheck(e.target.checked)}}/>
                <span className="text-gray-500 ml-2 ">Acepto la regulación europea de protección de datos</span>
            </label>
         </div>
         <span class="block m-2 p-4 text-left text-sm text-gray-200"> Una vez hayas enviado el formulario de solicitud recibirás un e-mail para programar una entrevista.</span>
    </div>

    

    <div className="flex flex-wrap -mx-3 mt-6">
        <div className="w-full px-3">
            <button className="btn text-gray-900 bg-green-100 hover:bg-green-200 hover:text-green-100 w-full" disabled={is.email(email)} onClick={sendEmail}>¡Solicita tu plaza ahora!</button>
            </div>
        </div>
    
    </form>

</div> }

          <Modal id="modal" ariaLabel="modal-headline" show={videoModalOpen} handleClose={() => setVideoModalOpen(false)}>
              <div className="relative pb0">
                  <Thanks/>
              </div>
          </Modal>
      </>
  );
}

export default HeroLandingForm;
