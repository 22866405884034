import Storage from './storage';
import User from './common/models/user';

var currentUser: User;
var currentCountry: String;
var currentDossier: String;

var getCurrentUser = async (): Promise<User> => {
    var data = await Storage.read('user');
    currentUser = User.prototype.load(data);
    currentUser.id=data.id;
    return currentUser;
}

var getCurrentCountry = async (): Promise<string> => {
    var data = await Storage.read('country');
    return data;
}

const getEnv = (name: string, defaultValue: string): string => {
    if(!(window as any).envars) return defaultValue;
    return (window as any).envars[name] ? (window as any).envars[name] : defaultValue;
}

var Settings = {
    hasSeenTutorial: async (): Promise<boolean> => {
        return await Storage.read('hasSeenTutorial');
    },

    markTutorialAsSeen: (): void => {
        Storage.write('hasSeenTutorial', true);
    },

    currentUser: () : User => currentUser,

    getCurrentUser: getCurrentUser,
    getCurrentCountry: getCurrentCountry,

    isLoggedIn: async (): Promise<boolean> => {
        try {
            var currentUser: User = await getCurrentUser();
            console.log('current user:', currentUser);
            return currentUser ? true : false;
        } catch (err) {
            return false;
        }
    },

    setCurrentUser: async (user: User): Promise<void> => {
        currentUser = user;
        return await Storage.write('user', user);
    },

    setCurrentDossier: async (dossierId: string): Promise<void> => {
        currentDossier = dossierId;
        return await Storage.write('dossier', dossierId);
    },

    setCurrentCountry: async (country: String): Promise<void> => {
        currentCountry = country;
        return await Storage.write('country', country);
    },
    logout: async () => {
        return await Storage.clear('user');
    },

    getApiURL: (): string => {
        //return window.location.protocol + '//' + window.location.hostname + ':8080/api';
        return '/api';
        //return window.location.protocol + '//' + window.location.hostname + ':3000/api';
    },

    getPublicURL: (): string => {
        return window.location.protocol + '//' + window.location.hostname+'/qr/';
    },

    getNumDaysToReturn: (): number => {
        return 15;
    },
    getResiduesPerUnit: (): number => {
        return 0.014;
    },

    getVersion: (): string => {
        return getEnv('VERSION', '1.2.1');
    },

    getStripeKey: (): string => {
        return getEnv('PUBLIC_STRIPE_KEY', 'pk_test_t1WHSW3dlbCyWaJe8vStOrxf00Gl3m9aWM')
    }
}

export default Settings;
