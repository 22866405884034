import Dossier,{DossierCategory} from '../lib/common/models/dossier';

//------------  state definition
export interface DossierState {
    dossier: Dossier
    dossiers: Dossier[]
    ready: boolean
}

//------------ actions
export const SET_DOSSIER_DOC = 'SET_DOSSIER_DOC'
export const GET_DOSSIER_LIST = 'GET_DOSSIER_LIST'

interface SetCurrentDossierAction {
  type: typeof SET_DOSSIER_DOC
  payload: Dossier
}

interface GetDossiersAction {
  type: typeof GET_DOSSIER_LIST
  payload: Dossier[]
}

export function setCurrentDossier(doc: Dossier): DocsActionTypes {
    return {
      type: SET_DOSSIER_DOC,
      payload: doc
    }
}
export function setCurrentDossiers(drafts: Dossier[]) : DocsActionTypes  {
    return {
      type: GET_DOSSIER_LIST,
      payload: drafts
    }
}

export type DocsActionTypes = SetCurrentDossierAction | GetDossiersAction ;

//------------ reducer
const initialState: DossierState = {
    dossier: new Dossier({category:DossierCategory.GENERAL_PURPOSE,name:'',description:'', url:''}),
    ready: false,
    dossiers: []

}

export function dossierReducer( state = initialState, action: DocsActionTypes): DossierState {
  switch (action.type) {
    case SET_DOSSIER_DOC: {
      return {
        ...state,
          dossier: action.payload,
          ready: true
      }
    }
    case GET_DOSSIER_LIST: {
      return {
        ...state,
          dossiers: action.payload,
          ready: true
      }
    }
    default:
      return state
  }
}
