import React, { useEffect } from 'react';
import { Switch, Route, useLocation} from 'react-router-dom';
import configureStore from "./store/store";
import { withRouter } from "react-router";

import './css/style.scss';

import AOS from 'aos';
import { focusHandling } from 'cruip-js-toolkit';

//  
import Home from './pages/Home';
import Apiumtalent from './pages/Apiumtalent';
import Features from './pages/Features';
import Bootcamp from './pages/Bootcamp';
import Pricing from './pages/Pricing';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';
import About from './pages/About';
import Contact from './pages/Contact';
import Help from './pages/Help';
import Candidatos from './pages/Candidatos';
import SignUp from './pages/SignUp';
import ResetPassword from './pages/ResetPassword';
import PageNotFound from './pages/PageNotFound';
import {Provider} from "react-redux";
import Toast from "./shared/components/Toast";
import Verify from "./pages/Verify";
import Registered from "./pages/Registered";
import SignIn from "./pages/SignIn";
import Apiumuser from "./pages/apiumuser";
import BootcampSteps from "./pages/BootcampSteps";
import BootcampProgram from "./pages/BootcampProgram";
import BootcampSponsors from "./pages/BootcampSponsors";
import Convierteteendesarrolador from "./pages/convierteteendesarrolador";

function App() {

  const location = useLocation();

  useEffect(() => {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 600,
      easing: 'ease-out-sine',
    });
  });

  useEffect(() => {
    window.scrollTo(0, 0)
    focusHandling();
  }, [location]); // triggered on route change

  return (
    <>
      <Provider store={store}>
        <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/features">
          <Features />
        </Route>
        <Route path="/apiumtalent">
          <Apiumtalent />
        </Route>
        <Route path="/pricing">
          <Pricing />
        </Route>
        <Route path="/blog">
          <Blog />
        </Route>
        <Route path="/blog-post">
          <BlogPost />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
        <Route path="/help">
          <Help />
        </Route>
        <Route path="/candidatos">
          <Candidatos />
        </Route>
        <Route path="/empresas">
          <SignUp />
        </Route>

        <Route path="/convierteteendesarrolador"><Convierteteendesarrolador /></Route>

        <Route path="/apiumuser"><Apiumuser /></Route>
        <Route path="/signin"><SignIn /></Route>
        <Route path="/signup"><SignUp /></Route>
        <Route path="/reset-password"><ResetPassword /></Route>
        <Route path="/registered"><Registered /></Route>

        <Route exact path="/security/verify/:email/:token" render={(props) => <Verify {...props} /> } ></Route>

        <Route path="/bootcamp" exact><Bootcamp /></Route>
        <Route path="/bootcamp/programa"><BootcampProgram /></Route>
        <Route path="/bootcamp/profesores"><BootcampSteps /></Route>
        <Route path="/bootcamp/mecanica"><BootcampSteps /></Route>
        <Route path="/bootcamp/sponsors"><BootcampSponsors /></Route>
        <Route path="/bootcamp/empresas"><BootcampSteps /></Route>

        <Route path="*">
          <PageNotFound />
        </Route>

      </Switch>
        <Toast/>
      </Provider>
    </>
  );
}

const store = configureStore();
export default withRouter(props => <App store={store} {...props} />);
